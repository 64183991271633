import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getData } from "common/utils";
import { useTable } from "react-table";
import TextField from "components/form/TextField";
import Select from "components/form/Select";
import ReactSelect from "react-select";
import Tooltip from "components/ui/Tooltip";
import Alert from "react-bootstrap/Alert";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  const productList = [
    { value: "cdn", label: "cdn" },
    { value: "storage", label: "storage" },
  ];
  const [promoCodeList, setPromoCodeList] = useState([]);

  const { data: dataPromoCode } = useQuery(["getOrderPromoCode"], async () => {
    return await getData("GET", "admin/PromoCode");
  });

  useEffect(() => {
    if (dataPromoCode && Array.isArray(dataPromoCode)) {
      const dataTemp = dataPromoCode.map((item) => {
        return {
          value: item.promo_code,
          label: item.promo_code + " - " + item.discount + "%",
        };
      });

      setPromoCodeList(dataTemp);
    }
  }, [dataPromoCode]);

  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onChangeSelect = (newValue) => {
    setValue(newValue);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <>
      {id === "price_override" ? (
        <div className="input-group">
          <input
            className="form-control"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
          <span className="input-group-text"> &#8363;</span>
        </div>
      ) : (
        <>
          {id === "product" ? (
            <ReactSelect
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              options={productList}
              value={value}
              onChange={onChangeSelect}
              onBlur={onBlur}
            />
          ) : (
            <ReactSelect
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              options={promoCodeList}
              value={value}
              onChange={onChangeSelect}
              onBlur={onBlur}
            />
          )}
        </>
      )}
    </>
  );
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

function Table({ columns, data, updateMyData, skipPageReset }) {
  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({
      columns,
      data,
      defaultColumn,
      updateMyData,
    });

  // Render the UI for your table
  return (
    <>
      <table
        {...getTableProps()}
        className="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline"
      >
        <thead className="table-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

// // Date Picker
// const CustomInput = forwardRef(({ value, onClick }, ref) => (
//   <div className="input-group clockpicker">
//     <input
//       className="form-control"
//       value={value}
//       onChange={() => {}}
//       onClick={onClick}
//       ref={ref}
//     />
//     <div className="input-group-append">
//       <span className="input-group-text">
//         <i className="mdi mdi-calendar"></i>
//       </span>
//     </div>
//   </div>
// ));

const OrderFormPage = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const queryClient = useQueryClient();

  // const [startDate, setStartDate] = useState(new Date())

  const [isShowAlert, setIsShowAlert] = useState(false);
  const [billingMethodList, setBillingMethodList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [orderNumber, setOrderNumber] = useState(null);
  const [productDataList, setProductDataList] = useState([
    {
      id: null,
      product: null,
      price_override: "",
      promo_code: null,
    },
  ]);

  const productList = [
    { value: "cdn", label: "cdn" },
    { value: "storage", label: "storage" },
  ];

  const statusList = [
    { value: 11, label: "Active" },
    { value: 10, label: "Inactive" },
    { value: 12, label: "Pending" },
  ];

  const columnProductDataList = [
    {
      Header: () => <div className="text-center">Action</div>,
      accessor: "action",
      Cell: (props) => {
        const indexValue = props.row && props.row.index;

        return (
          <div className="text-center">
            <Tooltip message="Delete">
              <i
                className="bx bx-trash font-size-20 text-danger me-2 mt-2"
                role="button"
                onClick={(e) => handleClickDeleteProduct(e, indexValue)}
              ></i>
            </Tooltip>
          </div>
        );
      },
    },
    {
      Header: "Product",
      accessor: "product",
    },
    {
      Header: "Price Override",
      accessor: "price_override",
    },
    {
      Header: "Promo Code",
      accessor: "promo_code",
    },
  ];

  const { data: dataInfoOrder } = useQuery(["getInfoOrder", id], async () => {
    if (id) {
      return await getData("GET", `admin/orders/${id}`);
    } else {
      return null;
    }
  });

  const { data: dataListOrderItem } = useQuery(
    ["getListOrderItem", orderNumber],
    async () => {
      if (id) {
        return await getData("GET", `admin/orderItem/${orderNumber}`);
      } else {
        return null;
      }
    }
  );

  const { data: dataAllUser } = useQuery(["getOrderAllUser"], async () => {
    return await getData("GET", "admin/getAllUser");
  });

  const { data: dataBillingMethod } = useQuery(
    ["getBillingMethod"],
    async () => {
      return await getData("GET", `admin/BillingMethods/${id}`);
    }
  );

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData("PUT", `admin/orders/${id}`, dataForm);
      } else {
        return await getData("POST", "admin/orders", dataForm);
      }
    }
  });

  const mutationOrderItem = useMutation(async (dataForm) => {
    if (dataForm) {
      return await getData("POST", `admin/orderItem/${orderNumber}`, dataForm);
    }
  });

  const mutationDelete = useMutation(
    async (idParam) => {
      if (idParam) {
        return await getData(
          "DELETE",
          `admin/orderItem/${orderNumber}/${idParam}`
        );
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getListOrderItem");
      },
    }
  );

  useEffect(() => {
    if (dataInfoOrder) {
      setOrderNumber(dataInfoOrder.order_number);
      // console.log(dataInfoOrder);

      setValue("order_number", dataInfoOrder.order_number);
      setValue("billing_cycle", dataInfoOrder.billing_cycle);

      const accountFind = userList.find(
        (item) => item.value === dataInfoOrder.uid
      );
      setValue("uid", accountFind);

      const billingMethodFind = billingMethodList.find(
        (item) => item.label === dataInfoOrder.billing_method
      );
      setValue("billing_method", billingMethodFind);

      const statusFind = statusList.find(
        (item) => item.value === dataInfoOrder.status
      );
      setValue("status", statusFind);

      // for (let i = 0; i < dataInfoOrder.length; i++) {
      //   if (`${dataInfoOrder[i].id}` === id) {
      //     setGroupName(dataInfoOrder[i].group_name);
      //     setValue(
      //       "group_name",
      //       dataInfoOrder[i].group_name ? dataInfoOrder[i].group_name : ""
      //     );
      //   }
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInfoOrder, billingMethodList, userList]);

  useEffect(() => {
    if (dataAllUser && Array.isArray(dataAllUser)) {
      const dataTemp = dataAllUser.map((item) => {
        return {
          value: item.id,
          label: item.email,
        };
      });

      setUserList(dataTemp);
    }
  }, [dataAllUser]);

  useEffect(() => {
    if (dataBillingMethod) {
      const resultData = dataBillingMethod.data;

      if (resultData && typeof resultData === "object") {
        const dataTemp = Object.keys(resultData).map((item) => {
          return {
            value: item,
            label: resultData[item],
          };
        });
        setBillingMethodList(dataTemp);
      }
    }
  }, [dataBillingMethod]);

  useEffect(() => {
    if (
      dataListOrderItem &&
      Array.isArray(dataListOrderItem) &&
      dataListOrderItem.length > 0
    ) {
      const dataTemp = dataListOrderItem.map((item) => {
        const productFind = productList.find(
          (itemFind) => itemFind.label === item.product
        );

        return {
          id: item.id,
          product: productFind,
          price_override: item.price_override,
          promo_code: item.promo_code,
        };
      });

      setProductDataList(dataTemp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataListOrderItem]);

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    try {
      if (id) {
        await mutation.mutateAsync({
          billing_cycle: data.billing_cycle,
          billing_method: data.billing_method && data.billing_method.label,
          status: data.status && data.status.value,
        });

        if (Array.isArray(dataListOrderItem)) {
          for (let i = 0; i < dataListOrderItem.length; i++) {
            const productItemTemp = dataListOrderItem[i];
            if (productItemTemp) {
              await mutationDelete.mutateAsync(productItemTemp.id);
            }
          }
        }

        for (let i = 0; i < productDataList.length; i++) {
          const lastProductItem = productDataList[i];

          if (
            lastProductItem &&
            lastProductItem.product &&
            lastProductItem.product !== ""
          ) {
            await mutationOrderItem.mutateAsync({
              product: lastProductItem.product && lastProductItem.product.value,
              price_override:
                lastProductItem.price_override === ""
                  ? 0
                  : lastProductItem.price_override,
              promo_code:
                lastProductItem.promo_code && lastProductItem.promo_code.value,
            });
          }
        }
      } else {
        const productTemp = productDataList.map((item) => {
          return {
            product: item.product && item.product.value,
            price_override: item.price_override,
            // promo_code: item.promo_code && item.promo_code.value
            promo_code: null,
          };
        });

        await mutation.mutateAsync({
          uid: data.uid && data.uid.value,
          billing_cycle: data.billing_cycle,
          billing_method: data.billing_method && data.billing_method.label,
          products: productTemp,
        });
      }

      setIsShowAlert(false);
      navigate(-1);
    } catch (err) {
      console.log(err);
      setIsShowAlert(true);
    }
  };

  const handleClickCancel = () => {
    navigate(-1);
  };

  const updateMyData = (rowIndex, columnId, value) => {
    setProductDataList((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const handleClickAddProduct = () => {
    if (productDataList.length < 2) {
      const dataTemp = [...productDataList];
      dataTemp.push({
        product: "",
        price_override: "",
        promo_code: "",
      });
      setProductDataList(dataTemp);
    }
  };

  const handleClickDeleteProduct = async (e, index) => {
    // if (id) {
    //   try {
    //     const productItemTemp = productDataList[index];
    //     if (productItemTemp) {
    //       await mutationDelete.mutateAsync(productItemTemp.id);
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   }
    // } else {
    //   const dataTemp = [...productDataList];
    //   dataTemp.splice(index, 1);
    //   setProductDataList(dataTemp);
    // }

    const dataTemp = [...productDataList];
    dataTemp.splice(index, 1);
    setProductDataList(dataTemp);
  };

  const handleChangeBillingCycle = (e) => {
    if (e && e.target) {
      if (e.target.value < 1) {
        setValue("billing_cycle", 1);
      }

      if (e.target.value > 28) {
        setValue("billing_cycle", 28);
      }
    }
  };

  // const handleChangeDate = (date) => {
  //   setStartDate(date)
  // }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            {isShowAlert && (
              <div className="row mb-2">
                <div className="col-md-9">
                  <Alert variant="danger">Something went wrong!</Alert>
                </div>
              </div>
            )}

            {id && (
              <div className="row mb-4">
                <TextField
                  label="Order Number"
                  labelClassName="col-md-3 col-form-label"
                  fieldClassName="col-md-6"
                  name="order_number"
                  disabled
                  control={control}
                  error={errors.order_number}
                />
              </div>
            )}

            <div className="row mb-4">
              <Select
                label="Account"
                labelClassName="col-md-3 col-form-label"
                fieldClassName="col-md-6"
                required
                disabled={!!id}
                name="uid"
                options={userList}
                control={control}
                error={errors.uid}
              />
            </div>

            <div className="row mb-4">
              <Select
                label="Billing Method"
                labelClassName="col-md-3 col-form-label"
                fieldClassName="col-md-6"
                name="billing_method"
                required
                options={billingMethodList}
                control={control}
                error={errors.billing_method}
              />
            </div>

            <div className="row mb-4">
              <TextField
                type="number"
                label="Billing Cycle"
                labelClassName="col-md-3 col-form-label"
                fieldClassName="col-md-6"
                name="billing_cycle"
                required
                onHandleChange={handleChangeBillingCycle}
                control={control}
                error={errors.billing_cycle}
              />

              {/* <label className="col-md-3 col-form-label">Billing Cycle *</label>
              <div className="col-md-6">
                <DatePicker
                  portalId="root-portal-order"
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  maxDate={new Date()}
                  onChange={handleChangeDate}
                  customInput={<CustomInput />}
                />
              </div> */}
            </div>

            {id && (
              <div className="row mb-4">
                <Select
                  label="Status"
                  labelClassName="col-md-3 col-form-label"
                  fieldClassName="col-md-6"
                  name="status"
                  options={statusList}
                  control={control}
                  error={errors.status}
                />
              </div>
            )}

            <div className="row mb-3">
              <div className="col-md-2">
                <button
                  className="btn btn-secondary"
                  onClick={handleClickAddProduct}
                >
                  Add Product
                </button>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-9">
                <div className="table-responsive">
                  <Table
                    columns={columnProductDataList}
                    data={productDataList}
                    updateMyData={updateMyData}
                  />
                </div>
              </div>
            </div>

            <div className="mt-5 row justify-content-end">
              <div className="col-md-9 offset-md-3">
                <button
                  type="submit"
                  className="btn btn-secondary w-md me-4"
                  onClick={handleClickCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary w-md"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderFormPage;
