import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  getData,
  formatYearMonth,
  formatThousand,
  getChartBackgroundColor,
  getChartBorderColor,
} from "common/utils";
import LineChart from "components/chart/LineChart";
import Spinner from "components/ui/Spinner";
import Table from "components/ui/Table";

const HomeCdnTransferDate = (props) => {
  const { provider, dataCenter, cdn, startDate } = props;

  const [dataList, setDataList] = useState([]);
  const [labelChartList, setLabelChartList] = useState([]);
  const [dataChartList, setDataChartList] = useState([]);

  const columns = [
    {
      Header: "Date",
      accessor: "date(rdate)",
    },
    {
      Header: "GB",
      accessor: "gbytes",
    },
  ];

  const {
    data: dataInDate,
    isLoading,
    isFetching,
  } = useQuery(
    ["getHomeCdnTransferDate", provider, dataCenter, cdn, startDate],
    async () => {
      if (provider && dataCenter && cdn && startDate) {
        return await getData(
          "GET",
          `count/cdndataindate/${provider.value}/${dataCenter.value}/${
            cdn.value
          }/${formatYearMonth(startDate)}`
        );
      } else {
        return null;
      }
    }
  );

  useEffect(() => {
    if (dataInDate && Array.isArray(dataInDate)) {
      const dataTemp = dataInDate.map((item, index) => {
        return {
          key: index,
          "date(rdate)": item["date(rdate)"],
          gbytes: formatThousand(item.gbytes),
        };
      });

      const dateTemp = dataInDate.map((item) => {
        const dateStr = item["date(rdate)"];
        if (typeof dateStr === "string") {
          return dateStr.slice(-2);
        } else {
          return "";
        }
      });
      const gbytesTemp = dataInDate.map((item) => {
        return item.gbytes;
      });

      setDataList(dataTemp);
      setLabelChartList(dateTemp);
      setDataChartList([
        {
          fill: true,
          lineTension: 0.5,
          backgroundColor: getChartBackgroundColor(0),
          borderColor: getChartBorderColor(0),
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: getChartBorderColor(0),
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: getChartBorderColor(0),
          pointHoverBorderColor: "#fff",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          label: "GB",
          data: gbytesTemp,
          barThickness: 30,
          borderWidth: 3,
        },
      ]);
    }
  }, [dataInDate]);

  return (
    <div>
      {isLoading || isFetching ? (
        <Spinner />
      ) : (
        <LineChart
          id="home-cdn-transfer-date-chart"
          xLabel="Date"
          yLabel="GB"
          labels={labelChartList}
          datasets={dataChartList}
        />
      )}

      <div className="card">
        <div className="card-body">
          <Table columns={columns} data={dataList} />
        </div>
      </div>
    </div>
  );
};

export default HomeCdnTransferDate;
