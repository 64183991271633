import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getData } from "common/utils";
import TextField from "components/form/TextField";
import Select from "components/form/Select";
import Alert from "react-bootstrap/Alert";

const AccountFormPage = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm();

  const [isShowAlert, setIsShowAlert] = useState(false);
  const [groupList, setGroupList] = useState([]);

  const { data: dataAccountInfo } = useQuery(
    ["getInfoAccount", id],
    async () => {
      if (id) {
        return await getData("GET", `admin/AccountProfile/${id}`);
      } else {
        return null;
      }
    }
  );

  const { data: dataGroup } = useQuery(["getAccountGroup"], async () => {
    return await getData("GET", "admin/group");
  });

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData("PUT", `admin/AccountProfile/${id}`, dataForm);
      } else {
        return await getData("POST", "admin/AccountProfile", dataForm);
      }
    }
  });

  useEffect(() => {
    if (dataAccountInfo && groupList && Array.isArray(groupList)) {
      const resultData = dataAccountInfo[0];

      if (resultData) {
        setValue("email", resultData.email ? resultData.email : "");
        setValue("fullname", resultData.fullname ? resultData.fullname : "");
        setValue("phone", resultData.phone ? resultData.phone : "");
        setValue("address", resultData.address ? resultData.address : "");

        const groupFind = groupList.find(
          (item) => item.value === resultData.group_id
        );
        setValue("group_id", groupFind);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAccountInfo, groupList]);

  useEffect(() => {
    if (dataGroup && Array.isArray(dataGroup)) {
      const dataTemp = dataGroup.map((item) => {
        return {
          value: item.id,
          label: item.group_name,
        };
      });
      setGroupList(dataTemp);
    }
  }, [dataGroup]);

  const validateEmail = (mail) => {
    // eslint-disable-next-line no-useless-escape
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  };

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    try {
      if (data.password !== data.password_confirm) {
        setError("password_confirm", {
          type: "manual",
          message: "The password and confirmation password do not match!",
        });
      } else if (!validateEmail(data.email)) {
        setError("email", {
          type: "manual",
          message: "Invalid email address!",
        });
      } else {
        if (id) {
          if (data.password && data.password !== "") {
            await mutation.mutateAsync({
              email: data.email,
              fullname: data.fullname,
              phone: data.phone,
              address: data.address,
              timezone: "Asia/Bangkok",
              rstatus: 1,
            });
          } else {
            await mutation.mutateAsync({
              email: data.email,
              fullname: data.fullname,
              phone: data.phone,
              address: data.address,
              timezone: "Asia/Bangkok",
              rstatus: 1,
            });
          }
        } else {
          await mutation.mutateAsync({
            email: data.email,
            fullname: data.fullname,
            password: data.password,
            phone: data.phone,
            address: data.address,
            timezone: "Asia/Bangkok",
            rstatus: 1,
            group_id: data.group_id && data.group_id.value,
          });
        }

        setIsShowAlert(false);
        navigate(-1);
      }
    } catch (err) {
      console.log(err);
      setIsShowAlert(true);
    }
  };

  const handleClickCancel = () => {
    navigate(-1);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            {isShowAlert && (
              <div className="row mb-2">
                <div className="col-md-7">
                  <Alert variant="danger">Something went wrong!</Alert>
                </div>
              </div>
            )}
            <div className="row mb-4">
              <TextField
                label="Email"
                labelClassName="col-md-2 col-form-label"
                fieldClassName="col-md-5"
                name="email"
                required
                control={control}
                error={errors.email}
              />
            </div>

            <div className="row mb-4">
              <Select
                label="Group"
                labelClassName="col-md-2 col-form-label"
                fieldClassName="col-md-5"
                name="group_id"
                options={groupList}
                required={!id}
                disabled={!!id}
                control={control}
                error={errors.group_id}
              />
            </div>

            <div className="row mb-4">
              <TextField
                type="password"
                label="Password"
                labelClassName="col-md-2 col-form-label"
                fieldClassName="col-md-5"
                name="password"
                required={!id}
                control={control}
                error={errors.password}
              />
            </div>

            <div className="row mb-4">
              <TextField
                type="password"
                label="Confirm Password"
                labelClassName="col-md-2 col-form-label"
                fieldClassName="col-md-5"
                name="password_confirm"
                required={!id}
                control={control}
                error={errors.password_confirm}
              />
            </div>

            <div className="row mb-4">
              <TextField
                label="Fullname"
                labelClassName="col-md-2 col-form-label"
                fieldClassName="col-md-5"
                name="fullname"
                required
                control={control}
                error={errors.fullname}
              />
            </div>

            <div className="row mb-4">
              <TextField
                label="Phone"
                labelClassName="col-md-2 col-form-label"
                fieldClassName="col-md-5"
                name="phone"
                required
                control={control}
                error={errors.phone}
              />
            </div>

            <div className="row mb-4">
              <TextField
                type="textarea"
                label="Address"
                labelClassName="col-md-2 col-form-label"
                fieldClassName="col-md-5"
                name="address"
                // required
                control={control}
                error={errors.address}
              />
            </div>

            <div className="mt-5 row justify-content-end">
              <div className="col-md-10 offset-md-2">
                <button
                  type="submit"
                  className="btn btn-secondary w-md me-4"
                  onClick={handleClickCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary w-md"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountFormPage;
