import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getData } from "common/utils";
import Table from "components/ui/Table";
import Tooltip from "components/ui/Tooltip";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";

const AccountPage = (props) => {
  const queryClient = useQueryClient();

  const [userList, setUserList] = useState([]);
  const [accountKhmerHdList, setAccountKhmerHdList] = useState([]);

  const [show, setShow] = useState(false);
  const [idDelete, setIdDelete] = useState(null);

  const { data: dataAllUser } = useQuery(["getAllUser"], async () => {
    return await getData("GET", "admin/getAllUser");
  });

  const { data: dataAccountKhmerHd } = useQuery(
    ["getlistLoginV2"],
    async () => {
      return await getData("GET", "admin/listLoginV2");
    }
  );

  const mutationDelete = useMutation(
    async (id) => {
      if (id) {
        return await getData("DELETE", `admin/AccountProfile/${id}`);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllUser");
      },
    }
  );

  const columns = [
    {
      Header: () => <div className="text-center">Action</div>,
      accessor: "action",
      width: 100,
      minWidth: 120,
      Cell: (props) => {
        const originalValue =
          props.row && props.row.original && props.row.original;
        const idValue =
          props.row && props.row.original && props.row.original.id;

        return (
          <div className="text-center">
            <Tooltip message="Delete">
              <i
                className="bx bx-trash font-size-20 text-danger me-2"
                role="button"
                onClick={(e) => handleShow(e, idValue)}
              ></i>
            </Tooltip>
            <Tooltip message="Edit">
              <Link to={`/account/edit/${idValue}`}>
                <i
                  className="bx bx-edit-alt font-size-20 text-success ms-2 me-2"
                  role="button"
                ></i>
              </Link>
            </Tooltip>
            <Tooltip message="Login as">
              <i
                className="bx bx-log-in font-size-20 text-primary ms-2"
                role="button"
                onClick={(e) => handleClickLoginAs(e, originalValue)}
              ></i>
            </Tooltip>
          </div>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Fullname",
      accessor: "fullname",
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
  ];

  useEffect(() => {
    if (dataAllUser && Array.isArray(dataAllUser)) {
      const dataTemp = dataAllUser.map((item) => {
        return {
          key: item.id,
          ...item,
        };
      });
      setUserList(dataTemp);
    }
  }, [dataAllUser]);

  useEffect(() => {
    if (dataAccountKhmerHd && Array.isArray(dataAccountKhmerHd)) {
      setAccountKhmerHdList(dataAccountKhmerHd);
    }
  }, [dataAccountKhmerHd]);

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleClickLoginAs = (e, item) => {
    if (item) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}admin/touser/${item.id}`,
          {
            username: item.username,
            password: item.password,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((result) => {
          const resultData = result.data;
          if (resultData) {
            const token = resultData.sessionid;
            const username = item.email;

            const itemEmail = item.email;
            let isKhmerHd = false;

            for (let i = 0; i < accountKhmerHdList.length; i++) {
              if (itemEmail === accountKhmerHdList[i]) {
                isKhmerHd = true;
              }
            }

            if (isKhmerHd) {
              window.open(
                `https://portal.khmer-hd.com/login/?token=${token}&username=${username}`,
                "_blank"
              );
            } else {
              window.open(
                `https://portal.vnns.io/login/?token=${token}&username=${username}`,
                "_blank"
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = (e, idValue) => {
    setIdDelete(idValue);
    setShow(true);
  };

  const handleClickDelete = async () => {
    try {
      await mutationDelete.mutateAsync(idDelete);
      setShow(false);

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Deleted successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (err) {
      setShow(false);
      console.log(err);
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <Table columns={columns} data={userList} />
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action cannot be undone. Are you sure you want to delete this
          item?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleClickDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AccountPage;
