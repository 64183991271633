import React from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";

const Checkbox = (props) => {
  const { className, label, name, control, defaultChecked, disabled } = props;

  return (
    <div className={`${className === "" ? "" : " " + className}`}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <div className="form-check">
              <input
                checked={typeof value === "undefined" ? defaultChecked : value}
                disabled={disabled}
                onChange={onChange}
                className="form-check-input"
                type="checkbox"
              />
              <label className="form-check-label">{label}</label>
            </div>
          );
        }}
      />
    </div>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.any,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  // error: PropTypes.any
};

Checkbox.defaultProps = {
  className: "",
  label: "",
  name: "",
  defaultChecked: false,
  disabled: false,
};

export default Checkbox;
