import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTable, usePagination } from "react-table";
import "./styles/table.scss";

const Table = (props) => {
  const { columns, data } = props;

  const [paginationList, setPaginationList] = useState([]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination
  );

  useEffect(() => {
    if (pageCount > 0) {
      const paginationArr = paginationFunc(pageIndex, pageCount);
      setPaginationList(paginationArr);
    }
  }, [pageCount, pageIndex]);

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
  };

  const paginationFunc = (c, m) => {
    var current = c,
      last = m,
      delta = 2,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l;

    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  };

  return (
    <div className="adm-table">
      <div className="row mb-2">
        <div className="col-sm-12 col-md-6">
          <div className="dataTables_length">
            <label className="d-inline-flex align-items-center">
              Show
              <select
                className="form-control form-control-sm mx-2"
                onChange={handleChangePageSize}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              entries
            </label>
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <table
          {...getTableProps()}
          className="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline"
        >
          <thead className="table-light">
            {headerGroups.map((headerGroup, indexHeaderGroup) => {
              return (
                <tr
                  key={indexHeaderGroup}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column, indexColumn) => {
                    return (
                      <th
                        key={indexColumn}
                        {...column.getHeaderProps({
                          style: {
                            maxWidth: column.maxWidth,
                            minWidth: column.minWidth,
                            width: column.width,
                          },
                        })}
                      >
                        {column.render("Header")}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, indexRow) => {
              prepareRow(row);
              return (
                <tr key={indexRow} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td
                        key={indexCell}
                        {...cell.getCellProps({
                          style: {
                            maxWidth: cell.maxWidth,
                            minWidth: cell.minWidth,
                            width: cell.width,
                          },
                        })}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}

            {/* {rows.length === 0 && (
              <tr>
                <td
                  colSpan={Array.isArray(columns) ? columns.length : 0}
                  className="no-data"
                >
                  No data
                </td>
              </tr>
            )}*/}
          </tbody>
        </table>
      </div>

      <div className="row justify-content-md-between align-items-md-center mt-2">
        <div className="col-sm-12 col-md-5">
          <div className="dataTables_info mb-2">{data?.length} entries</div>
        </div>
        <div className="col-sm-12 col-md-5">
          <div className="text-md-right float-md-end pagination-rounded">
            <ul className="pagination">
              <li
                className={`pagination-prev page-item ${
                  !canPreviousPage ? "disabled" : ""
                }`}
              >
                <div className="page-link" onClick={() => gotoPage(0)}>
                  &#124;&lt;
                </div>
              </li>
              <li
                className={`pagination-prev page-item ${
                  !canPreviousPage ? "disabled" : ""
                }`}
              >
                <div className="page-link" onClick={() => previousPage()}>
                  &lt;
                </div>
              </li>

              {paginationList &&
                paginationList.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {isNaN(item) || item === "..." ? (
                        <li className="pagination-page page-item">
                          <div className="page-link adm-viewmore">...</div>
                        </li>
                      ) : (
                        <li
                          className={`pagination-page page-item ${
                            item - 1 === pageIndex ? "active" : ""
                          }`}
                          onClick={() => gotoPage(item - 1)}
                        >
                          <div className="page-link">{item}</div>
                        </li>
                      )}
                    </React.Fragment>
                  );
                })}

              <li
                className={`pagination-next page-item ${
                  !canNextPage ? "disabled" : ""
                }`}
              >
                <div className="page-link" onClick={() => nextPage()}>
                  &gt;
                </div>
              </li>
              <li
                className={`pagination-next page-item ${
                  !canNextPage ? "disabled" : ""
                }`}
              >
                <div
                  className="page-link"
                  onClick={() => gotoPage(pageCount - 1)}
                >
                  &gt;&#124;
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  dataTotal: PropTypes.any,
};

Table.defaultProps = {
  columns: [],
  data: [],
};

export default Table;
