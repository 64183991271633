import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getData } from "common/utils";
import Table from "components/ui/Table";
import Tooltip from "components/ui/Tooltip";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import CardStat from "components/ui/CardStat";
import Swal from "sweetalert2";

const InvoicePage = (props) => {
  const queryClient = useQueryClient();

  const [dataList, setDataList] = useState([]);
  const [show, setShow] = useState(false);
  const [idDelete, setIdDelete] = useState(null);

  const statusSelectList = [
    { value: "all", label: "All" },
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
    { value: "pending", label: "Pending" },
  ];
  const [statusSelect, setStatusSelect] = useState({
    value: "all",
    label: "All",
  });
  const [totalNumber, setTotalNumber] = useState(0);
  const [activeNumber, setActiveNumber] = useState(0);
  const [inActiveNumber, setInActiveNumber] = useState(0);
  const [pendingNumber, setPendingNumber] = useState(0);

  const { data: dataInvoice } = useQuery(
    ["getInvoice", statusSelect],
    async () => {
      return await getData("GET", "admin/invoice");
    }
  );

  const mutationDelete = useMutation(
    async (id) => {
      if (id) {
        return await getData("DELETE", `admin/orders/${id}`);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getOrders");
      },
    }
  );

  const columns = [
    {
      Header: () => <div className="text-center">Action</div>,
      accessor: "action",
      width: 120,
      minWidth: 120,
      maxWidth: 120,
      Cell: (props) => {
        const idValue =
          props.row && props.row.original && props.row.original.id;

        return (
          <div className="text-center">
            <Tooltip message="Delete">
              <i
                className="bx bx-trash font-size-20 text-danger me-2"
                role="button"
                onClick={(e) => handleShow(e, idValue)}
              ></i>
            </Tooltip>
            <Tooltip message="Edit">
              <Link to={`/order/edit/${idValue}`}>
                <i
                  className="bx bx-edit-alt font-size-20 text-success ms-2 me-2"
                  role="button"
                ></i>
              </Link>
            </Tooltip>
          </div>
        );
      },
    },
    {
      Header: "Order Number",
      accessor: "order_number",
    },
    {
      Header: "Billing Method",
      accessor: "billing_method",
    },
    {
      Header: "Date",
      accessor: "rdate",
    },
    {
      Header: "Status",
      accessor: "status",
    },
  ];

  useEffect(() => {
    if (dataInvoice && Array.isArray(dataInvoice)) {
      console.log(dataInvoice);

      const dataTemp = [];
      const totalTemp = dataInvoice.length;
      let inActiveTemp = 0;
      let activeTemp = 0;
      let pendingTemp = 0;

      for (let i = 0; i < dataInvoice.length; i++) {
        const item = dataInvoice[i];

        if (item.status === 10 || item.status === null) {
          inActiveTemp += 1;
        } else if (item.status === 11) {
          activeTemp += 1;
        } else if (item.status === 12) {
          pendingTemp += 1;
        }

        const objTemp = {
          key: item.id,
          id: item.id,
          order_number: item.order_number,
          billing_method: item.billing_method,
          rdate: item.rdate,
          status:
            item.status === 10 || item.status === null
              ? "Inactive"
              : item.status === 11
              ? "Active"
              : "Pending",
        };

        if (statusSelect.value === "all") {
          dataTemp.push(objTemp);
        } else if (statusSelect.value === "inactive") {
          if (item.status === 10 || item.status === null) {
            dataTemp.push(objTemp);
          }
        } else if (statusSelect.value === "active") {
          if (item.status === 11) {
            dataTemp.push(objTemp);
          }
        } else if (statusSelect.value === "pending") {
          if (item.status === 12) {
            dataTemp.push(objTemp);
          }
        }
      }
      setDataList(dataTemp);

      // Formula
      setTotalNumber(totalTemp);
      setInActiveNumber(inActiveTemp);
      setActiveNumber(activeTemp);
      setPendingNumber(pendingTemp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInvoice]);

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleClose = () => setShow(false);
  const handleShow = (e, idValue) => {
    setIdDelete(idValue);
    setShow(true);
  };

  const handleClickDelete = async () => {
    try {
      await mutationDelete.mutateAsync(idDelete);
      setShow(false);

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Deleted successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (err) {
      setShow(false);
      console.log(err);
    }
  };

  const handleChangeStatus = (value) => {
    setStatusSelect(value);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row">
          <CardStat
            title="Total"
            value={totalNumber}
            icon="bx-copy-alt"
            iconColor="bg-secondary"
          />
          <CardStat
            title="Active"
            value={activeNumber}
            icon="bx-copy-alt"
            iconColor="bg-primary"
          />
          <CardStat
            title="Inactive"
            value={inActiveNumber}
            icon="bx-copy-alt"
          />
          <CardStat title="Pending" value={pendingNumber} icon="bx-copy-alt" />
        </div>

        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-3">
                <label className="form-label">Status</label>
                <Select
                  options={statusSelectList}
                  value={statusSelect}
                  onChange={handleChangeStatus}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <Table columns={columns} data={dataList} />
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action cannot be undone. Are you sure you want to delete this
          item?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleClickDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default InvoicePage;
