import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { formatThousand } from "common/utils";
import Chart from "chart.js/auto";

const LineChart = (props) => {
  const { id, xLabel, yLabel, labels, datasets } = props;

  useEffect(() => {
    if (labels.length > 0 && datasets.length > 0) {
      const currentChart = Chart.getChart(id);

      if (currentChart) {
        //
      } else {
        const dataChart = {
          labels: labels,
          datasets: datasets,
        };

        const myChart = new Chart(document.getElementById(id), {
          type: "line",
          data: dataChart,
          options: {
            defaultFontColor: "#8791af",
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                title: {
                  display: true,
                  text: xLabel,
                  font: {
                    size: 14,
                    weight: "bold",
                  },
                },
              },
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: yLabel,
                  font: {
                    size: 14,
                    weight: "bold",
                  },
                },
                ticks: {
                  callback: (value, index, values) => {
                    if (value % 1 === 0) {
                      return formatThousand(value);
                    }
                  },
                },
              },
            },
          },
        });

        return () => {
          myChart.destroy();
        };
      }
    }
  }, [labels, datasets, id, xLabel, yLabel]);

  return (
    <div>
      <canvas id={id}></canvas>
    </div>
  );
};

LineChart.propTypes = {
  id: PropTypes.string,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
  labels: PropTypes.array,
  datasets: PropTypes.array,
};

LineChart.defaultProps = {
  id: "default-line-chart",
  xLabel: "",
  yLabel: "",
  labels: [],
  datasets: [],
};

export default LineChart;
