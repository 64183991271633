import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getData } from "common/utils";
import { Buffer } from "buffer";
import TextField from "components/form/TextField";
import Alert from "react-bootstrap/Alert";

const GroupFormPage = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [isShowAlert, setIsShowAlert] = useState(false);
  const [groupName, setGroupName] = useState("");

  const { data: dataInfoGroup } = useQuery(
    ["getInfoGroupList", id],
    async () => {
      if (id) {
        return await getData("GET", `admin/group`);
      } else {
        return null;
      }
    }
  );

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData("PUT", `admin/group/${id}`, dataForm);
      } else {
        return await getData("POST", "admin/group", dataForm);
      }
    }
  });

  useEffect(() => {
    if (dataInfoGroup && Array.isArray(dataInfoGroup)) {
      for (let i = 0; i < dataInfoGroup.length; i++) {
        if (`${dataInfoGroup[i].id}` === id) {
          setGroupName(dataInfoGroup[i].group_name);
          setValue(
            "group_name",
            dataInfoGroup[i].group_name ? dataInfoGroup[i].group_name : ""
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInfoGroup]);

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    try {
      if (id) {
        if (groupName === data.group_name) {
          // Duplicate
          //
        } else {
          const token = localStorage.getItem("token");
          if (token) {
            const tokenDecode = Buffer.from(token, "base64").toString("ascii");
            const tokenDecodeParse = JSON.parse(tokenDecode);
            if (tokenDecodeParse) {
              await mutation.mutateAsync({
                group_name: data.group_name,
                admin_id: tokenDecodeParse.id,
              });
            }
          }
        }
      } else {
        const token = localStorage.getItem("token");
        if (token) {
          const tokenDecode = Buffer.from(token, "base64").toString("ascii");
          const tokenDecodeParse = JSON.parse(tokenDecode);
          if (tokenDecodeParse) {
            await mutation.mutateAsync({
              group_name: data.group_name,
              admin_id: tokenDecodeParse.id,
            });
          }
        }
      }

      setIsShowAlert(false);
      navigate(-1);
    } catch (err) {
      console.log(err);
      setIsShowAlert(true);
    }
  };

  const handleClickCancel = () => {
    navigate(-1);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            {isShowAlert && (
              <div className="row mb-2">
                <div className="col-md-7">
                  <Alert variant="danger">Something went wrong!</Alert>
                </div>
              </div>
            )}
            <div className="row mb-4">
              <TextField
                label="Group name"
                labelClassName="col-md-2 col-form-label"
                fieldClassName="col-md-5"
                name="group_name"
                required
                control={control}
                error={errors.group_name}
              />
            </div>

            <div className="mt-5 row justify-content-end">
              <div className="col-md-10 offset-md-2">
                <button
                  type="submit"
                  className="btn btn-secondary w-md me-4"
                  onClick={handleClickCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary w-md"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupFormPage;
