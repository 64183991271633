import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const MonthPicker = (props) => {
  const { startDate, maxDate, onChange } = props;

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="input-group clockpicker">
      <input
        className="form-control"
        value={value}
        onChange={() => {}}
        onClick={onClick}
        ref={ref}
      />
      <div className="input-group-append">
        <span className="input-group-text">
          <i className="mdi mdi-calendar"></i>
        </span>
      </div>
    </div>
  ));

  return (
    <DatePicker
      showMonthYearPicker
      portalId="root-portal"
      wrapperClassName="month-picker"
      dateFormat="MM/yyyy"
      maxDate={maxDate}
      selected={startDate}
      onChange={onChange}
      customInput={<CustomInput />}
    />
  );
};

MonthPicker.propTypes = {
  startDate: PropTypes.any,
  maxDate: PropTypes.any,
  onChange: PropTypes.func,
};

MonthPicker.defaultProps = {
  startDate: new Date(),
  maxDate: new Date(),
};

export default MonthPicker;
