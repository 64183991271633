import React from "react";
import { Link, useLocation } from "react-router-dom";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

const Sidebar = (props) => {
  const location = useLocation();

  const sidebarList = [
    {
      id: 1,
      label: "ANALYTICS",
      isTitle: true,
    },
    {
      id: 2,
      label: "Dashboard",
      icon: "bx-home-circle",
      url: "/",
    },
    {
      id: 3,
      label: "GROUP MANAGEMENT",
      isTitle: true,
    },
    {
      id: 4,
      label: "Group",
      icon: "bx-group",
      url: "/group",
    },
    {
      id: 5,
      label: "PROVIDER MANAGEMENT",
      isTitle: true,
    },
    {
      id: 6,
      label: "Provider",
      icon: "bx-buildings",
      url: "/provider",
    },
    {
      id: 7,
      label: "Provider Rule",
      icon: "bx-buildings",
      url: "/provider-rule",
    },
    {
      id: 8,
      label: "USER MANAGEMENT",
      isTitle: true,
    },
    {
      id: 9,
      label: "User",
      icon: "bx-user",
      url: "/user",
    },
    {
      id: 10,
      label: "Account",
      icon: "bx-user-pin",
      url: "/account",
    },
    {
      id: 11,
      label: "ROLE MANAGEMENT",
      isTitle: true,
    },
    {
      id: 12,
      label: "Role",
      icon: "bx-lock",
      url: "/role",
    },
    {
      id: 13,
      label: "ORDER MANAGEMENT",
      isTitle: true,
    },
    {
      id: 14,
      label: "Order",
      icon: "bx-shopping-bag",
      url: "/order",
    },
    {
      id: 15,
      label: "Promo Code",
      icon: "bx-money",
      url: "/promo-code",
    },
    // {
    //   id: 16,
    //   label: "Invoice",
    //   icon: "bx-money",
    //   url: "/invoice",
    // },
  ];

  const checkHasItems = (item) => {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  };

  return (
    <div className="vertical-menu">
      <SimpleBar className="h-100">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {sidebarList.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {item.isTitle && <li className="menu-title">{item.label}</li>}
                  {!item.isTitle && !item.isLayout && (
                    <li>
                      {checkHasItems(item) && (
                        <Link to={item.url} className="is-parent">
                          {item.icon && <i className={`bx ${item.icon}`}></i>}
                          <span>{item.label}</span>
                          {item.badge && (
                            <span
                              className={`badge rounded-pill float-end bg-${item.badge.variant}`}
                            >
                              {item.badge.text}
                            </span>
                          )}
                        </Link>
                      )}

                      {!checkHasItems(item) && (
                        <Link
                          to={item.url}
                          className={`side-nav-link-ref ${
                            location.pathname === item.url ? "active" : ""
                          }`}
                        >
                          {item.icon && <i className={`bx ${item.icon}`}></i>}
                          <span> {item.label}</span>
                          {item.badge && (
                            <span
                              className={`badge rounded-pill bg-${item.badge.variant} float-end`}
                            >
                              {item.badge.text}
                            </span>
                          )}
                        </Link>
                      )}
                    </li>
                  )}
                </React.Fragment>
              );
            })}
          </ul>
        </div>
      </SimpleBar>
    </div>
  );
};

export default Sidebar;
