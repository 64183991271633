import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import "../styles/topbar.scss";
import imgLogo from "../styles/images/logo.png";
import imgAvatar from "../styles/images/avatar.png";

const Topbar = (props) => {
  const [username, setUsername] = useState("");
  const [collapseChecked, setCollapseChecked] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("username")) {
      setUsername(localStorage.getItem("username"));
    }

    const sidebarCollapse = localStorage.getItem("sidebar_collapse");
    if (sidebarCollapse) {
      if (sidebarCollapse === "collapse") {
        document.body.classList.add("sidebar-enable");
        document.body.classList.add("vertical-collpsed");
        setCollapseChecked(true);
      } else {
        document.body.classList.remove("sidebar-enable");
        document.body.classList.remove("vertical-collpsed");
        setCollapseChecked(false);
      }
    }
  }, []);

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleClickLogout = async () => {
    try {
      if (localStorage.getItem("token")) {
        const sessionId = localStorage.getItem("token");
        // eslint-disable-next-line no-unused-vars
        const resultLogout = await axios({
          headers: {
            Authorization: `Bearer ${sessionId}`,
          },
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}logout`,
        });

        localStorage.removeItem("token");
        window.location.reload();
      } else {
        // localStorage.removeItem('token')
        window.location.reload();
      }
    } catch (err) {
      if (err.response) {
        const data = err.response.data;

        if (typeof data === "string") {
          // alert('Session expired, please login again.')
          localStorage.removeItem("token");
          window.location.reload();
        }
      }
      console.log(err);
    }
  };

  const handleClickToggleMenu = () => {
    if (!collapseChecked) {
      setCollapseChecked(true);
      document.body.classList.add("sidebar-enable");
      document.body.classList.add("vertical-collpsed");
      localStorage.setItem("sidebar_collapse", "collapse");
    } else {
      setCollapseChecked(false);
      document.body.classList.remove("sidebar-enable");
      document.body.classList.remove("vertical-collpsed");
      localStorage.setItem("sidebar_collapse", "expand");
    }

    if (window.screen.width <= 768) {
      document.body.classList.remove("vertical-collpsed");
    }
  };

  return (
    <header className="adm-topbar" id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={imgLogo} alt="" width="30" height="16" />
                {/* <span className="adm-logo-title-collapse">CDN Portal</span> */}
              </span>
              <span className="logo-lg">
                <img src={imgLogo} alt="" height="19" />
                <span className="adm-logo-title">Manager</span>
              </span>
            </Link>
          </div>

          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 header-item"
            id="vertical-menu-btn"
            onClick={handleClickToggleMenu}
          >
            <i className="fa fa-fw fa-bars"></i>
          </button>
        </div>

        <div className="d-flex">
          <Dropdown className="d-inline-block">
            <Dropdown.Toggle className="btn header-item dropdown-toggle">
              <img
                className="rounded-circle header-profile-user"
                src={imgAvatar}
                alt="Header Avatar"
              />
              <span className="d-none d-xl-inline-block ms-1">{username}</span>
              <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
              <Dropdown.Item
                className="dropdown-item text-danger"
                onClick={handleClickLogout}
              >
                <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </header>
  );
};

export default Topbar;
