import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getData } from "common/utils";
import TextField from "components/form/TextField";
import Select from "components/form/Select";
import Alert from "react-bootstrap/Alert";

const UserFormPage = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm();

  const [isShowAlert, setIsShowAlert] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [roleList, setRoleList] = useState([]);

  const { data: dataUserInfo } = useQuery(["getInfoUser", id], async () => {
    if (id) {
      return await getData("GET", `admin/users/${id}`);
    } else {
      return null;
    }
  });

  const { data: dataGroup } = useQuery(["getUserGroup"], async () => {
    return await getData("GET", "admin/group");
  });

  const { data: dataRole } = useQuery(["getUserRole"], async () => {
    return await getData("GET", "admin/roleadmin");
  });

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData("PUT", `admin/users/${id}`, dataForm);
      } else {
        return await getData("POST", "admin/users", dataForm);
      }
    }
  });

  useEffect(() => {
    if (dataUserInfo && groupList && Array.isArray(groupList)) {
      setValue("username", dataUserInfo.username ? dataUserInfo.username : "");

      const groupFind = groupList.find(
        (item) => item.value === dataUserInfo.group_id
      );
      setValue("group_id", groupFind);

      const roleFind = roleList.find(
        (item) => item.value === dataUserInfo.role_id
      );
      setValue("role_id", roleFind);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUserInfo, groupList, roleList]);

  useEffect(() => {
    if (dataGroup && Array.isArray(dataGroup)) {
      const dataTemp = dataGroup.map((item) => {
        return {
          value: item.id,
          label: item.group_name,
        };
      });
      setGroupList(dataTemp);
    }
  }, [dataGroup]);

  useEffect(() => {
    if (dataRole && Array.isArray(dataRole)) {
      const dataTemp = dataRole.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setRoleList(dataTemp);
    }
  }, [dataRole]);

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    try {
      if (data.password !== data.password_confirm) {
        setError("password_confirm", {
          type: "manual",
          message: "The password and confirmation password do not match!",
        });
      } else {
        if (id) {
          if (data.password && data.password !== "") {
            await mutation.mutateAsync({
              username: data.username,
              password: data.password,
              group_id: data.group_id && data.group_id.value,
              role_id: data.role_id && data.role_id.value,
              status: 1,
            });
          } else {
            await mutation.mutateAsync({
              username: data.username,
              password: dataUserInfo && dataUserInfo.password,
              group_id: data.group_id && data.group_id.value,
              role_id: data.role_id && data.role_id.value,
              status: 1,
            });
          }
        } else {
          await mutation.mutateAsync({
            username: data.username,
            password: data.password,
            group_id: data.group_id && data.group_id.value,
            role_id: data.role_id && data.role_id.value,
            status: 1,
          });
        }

        setIsShowAlert(false);
        navigate(-1);
      }
    } catch (err) {
      console.log(err);
      setIsShowAlert(true);
    }
  };

  const handleClickCancel = () => {
    navigate(-1);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            {isShowAlert && (
              <div className="row mb-2">
                <div className="col-md-7">
                  <Alert variant="danger">Something went wrong!</Alert>
                </div>
              </div>
            )}
            <div className="row mb-4">
              <TextField
                label="Username"
                labelClassName="col-md-2 col-form-label"
                fieldClassName="col-md-5"
                name="username"
                required
                control={control}
                error={errors.username}
              />
            </div>
            <div className="row mb-4">
              <TextField
                type="password"
                label="Password"
                labelClassName="col-md-2 col-form-label"
                fieldClassName="col-md-5"
                name="password"
                required={!id}
                control={control}
                error={errors.password}
              />
            </div>
            <div className="row mb-4">
              <TextField
                type="password"
                label="Confirm Password"
                labelClassName="col-md-2 col-form-label"
                fieldClassName="col-md-5"
                name="password_confirm"
                required={!id}
                control={control}
                error={errors.password_confirm}
              />
            </div>

            <div className="row mb-4">
              <Select
                label="Group"
                labelClassName="col-md-2 col-form-label"
                fieldClassName="col-md-5"
                name="group_id"
                required
                options={groupList}
                control={control}
                error={errors.group_id}
              />
            </div>

            <div className="row mb-4">
              <Select
                label="Role"
                labelClassName="col-md-2 col-form-label"
                fieldClassName="col-md-5"
                name="role_id"
                required
                options={roleList}
                control={control}
                error={errors.role_id}
              />
            </div>

            <div className="mt-5 row justify-content-end">
              <div className="col-md-10 offset-md-2">
                <button
                  type="submit"
                  className="btn btn-secondary w-md me-4"
                  onClick={handleClickCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary w-md"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserFormPage;
