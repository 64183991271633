import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getData, formatDate } from "common/utils";
import { parse } from "date-fns";
import TextField from "components/form/TextField";
import Alert from "react-bootstrap/Alert";
import DatePicker from "components/ui/DatePicker";
import Select from "react-select";

const PromoCodeFormPage = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [isShowAlert, setIsShowAlert] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const statusSelectList = [
    { value: 11, label: "Active" },
    { value: 10, label: "Inactive" },
    { value: 12, label: "Pending" },
  ];
  const [statusSelect, setStatusSelect] = useState({
    value: 12,
    label: "Pending",
  });

  const { data: dataInfoPromoCode } = useQuery(
    ["getInfoPromoCode", id],
    async () => {
      if (id) {
        return await getData("GET", `admin/PromoCode/${id}`);
      } else {
        return null;
      }
    }
  );

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData("PUT", `admin/PromoCode/${id}`, dataForm);
      } else {
        return await getData("POST", "admin/PromoCode", dataForm);
      }
    }
  });

  useEffect(() => {
    if (dataInfoPromoCode) {
      setValue("product", dataInfoPromoCode.product);
      setValue("discount", dataInfoPromoCode.discount);

      if (dataInfoPromoCode.date_start) {
        const dateParse = parse(
          dataInfoPromoCode.date_start,
          "yyyy-MM-dd HH:mm:ss",
          new Date()
        );
        setStartDate(dateParse);
      }

      if (dataInfoPromoCode.date_end) {
        const dateParse = parse(
          dataInfoPromoCode.date_end,
          "yyyy-MM-dd HH:mm:ss",
          new Date()
        );
        setEndDate(dateParse);
      }

      const statusInfo = dataInfoPromoCode.status;
      if (statusInfo === 10) {
        setStatusSelect({
          label: "Inactive",
          value: 10,
        });
      }
      if (statusInfo === 11) {
        setStatusSelect({
          label: "Active",
          value: 11,
        });
      }
      if (statusInfo === 12) {
        setStatusSelect({
          label: "Pending",
          value: 12,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInfoPromoCode]);

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    try {
      if (id) {
        const startDateParam = formatDate(startDate);
        const endDateParam = formatDate(endDate);

        await mutation.mutateAsync({
          product: data.product,
          discount: data.discount,
          date_start: startDateParam,
          date_end: endDateParam,
          status: statusSelect && statusSelect.value,
        });
      } else {
        const startDateParam = formatDate(startDate);
        const endDateParam = formatDate(endDate);

        await mutation.mutateAsync({
          product: data.product,
          discount: data.discount,
          date_start: startDateParam,
          date_end: endDateParam,
        });
      }

      setIsShowAlert(false);
      navigate(-1);
    } catch (err) {
      console.log(err);
      setIsShowAlert(true);
    }
  };

  const handleClickCancel = () => {
    navigate(-1);
  };

  const handleChangeStartDate = (date) => {
    setStartDate(date);
  };

  const handleChangeEndDate = (date) => {
    setEndDate(date);
  };

  const handleChangeStatus = (value) => {
    setStatusSelect(value);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            {isShowAlert && (
              <div className="row mb-2">
                <div className="col-md-7">
                  <Alert variant="danger">Something went wrong!</Alert>
                </div>
              </div>
            )}
            <div className="row mb-4">
              <TextField
                label="Product"
                labelClassName="col-md-2 col-form-label"
                fieldClassName="col-md-5"
                name="product"
                required
                control={control}
                error={errors.product}
              />
            </div>
            <div className="row mb-4">
              <TextField
                type="number"
                label="Discount"
                labelClassName="col-md-2 col-form-label"
                fieldClassName="col-md-5"
                name="discount"
                required
                control={control}
                error={errors.discount}
              />
            </div>
            <div className="row mb-4">
              <label className="col-md-2 col-form-label">Start Date</label>
              <div className="col-md-3">
                <DatePicker
                  startDate={startDate}
                  onChange={handleChangeStartDate}
                />
              </div>
            </div>
            <div className="row mb-4">
              <label className="col-md-2 col-form-label">End Date</label>
              <div className="col-md-3">
                <DatePicker
                  startDate={endDate}
                  onChange={handleChangeEndDate}
                />
              </div>
            </div>

            {id && (
              <div className="row mb-4">
                <label className="col-md-2 col-form-label">Status</label>
                <div className="col-md-3">
                  <Select
                    options={statusSelectList}
                    value={statusSelect}
                    onChange={handleChangeStatus}
                  />
                </div>
              </div>
            )}

            <div className="mt-5 row justify-content-end">
              <div className="col-md-10 offset-md-2">
                <button
                  type="submit"
                  className="btn btn-secondary w-md me-4"
                  onClick={handleClickCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary w-md"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromoCodeFormPage;
