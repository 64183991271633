import React from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import ReactSelect from "react-select";

const Select = (props) => {
  const {
    // className,
    layoutType,
    labelClassName,
    fieldClassName,
    label,
    name,
    required,
    disabled,
    control,
    error,
    options,
    onHandleChange,
  } = props;

  return (
    <>
      {layoutType === "horizontal" ? (
        <>
          <label className={labelClassName}>
            {`${label} ${required ? " *" : ""}`}
          </label>
          <div className={fieldClassName}>
            <Controller
              name={name}
              control={control}
              rules={{ required: required }}
              render={({ field: { onChange, value } }) => {
                return (
                  <>
                    <ReactSelect
                      options={options}
                      value={value || null}
                      isDisabled={disabled}
                      onChange={(newValue) => {
                        onChange(newValue);

                        if (
                          onHandleChange &&
                          typeof onHandleChange === "function"
                        ) {
                          onHandleChange(newValue);
                        }
                      }}
                    />

                    {error && (
                      <div className="invalid-feedback d-block">
                        {error.type === "required" && (
                          <div>{label} is required</div>
                        )}

                        {error.type === "manual" && <div>{error.message}</div>}
                      </div>
                    )}
                  </>
                );
              }}
            />
          </div>
        </>
      ) : (
        <div></div>
      )}
    </>
  );
};

Select.propTypes = {
  // className: PropTypes.string,
  layoutType: PropTypes.string,
  labelClassName: PropTypes.string,
  fieldClassName: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  rows: PropTypes.number,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  control: PropTypes.any,
  error: PropTypes.any,
  onInput: PropTypes.func,
  onKeyDown: PropTypes.func,
  onHandleChange: PropTypes.func,
};

Select.defaultProps = {
  // className: "",
  layoutType: "horizontal",
  labelClassName: "",
  fieldClassName: "",
  label: "",
  name: "",
  variant: "outlined",
  size: "small",
  rows: 4,
  fullWidth: true,
  required: false,
  disabled: false,
};

export default Select;
