import React from "react";
import { useForm } from "react-hook-form";
import { getLoginData } from "common/utils";
import "../styles/login.scss";
// import imgProfile from "assets/images/profile-img.png";
// import imgLogo from "assets/images/logo.svg";

const LoginPage = (props) => {
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (data) {
      try {
        const result = await getLoginData("POST", "admin/login", {
          username: data.username,
          password: data.password,
        });

        if (result.sessionid) {
          localStorage.setItem("token", result.sessionid);
          localStorage.setItem("username", data.username);
          localStorage.setItem("__sessionstate__", data.password); // password
          window.location.reload();
        }
      } catch (err) {
        if (err.response) {
          setError("username", {
            type: "manual",
            message:
              err.response.data && err.response.data.message
                ? err.response.data.message
                : "Error",
          });
        }
      }
    }
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <div className="account-pages my-5 pt-sm-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="card overflow-hidden">
              <div className="bg-soft bg-primary">
                <div className="row">
                  <div className="col-12">
                    <div className="text-primary text-center pt-4 px-4 pb-3">
                      <h5 className="text-primary">VNNS Manager</h5>
                      <p>Sign in to continue.</p>
                    </div>
                  </div>
                  {/* <div className="col-5 align-self-end">
                    <img src={imgProfile} alt="" className="img-fluid" />
                  </div> */}
                </div>
              </div>

              <div className="card-body pt-0">
                {/* <div>
                  <div className="avatar-md profile-user-wid mb-4">
                    <span className="avatar-title rounded-circle bg-light">
                      <img
                        src={imgLogo}
                        alt=""
                        className="rounded-circle"
                        height="34"
                      />
                    </span>
                  </div>
                </div> */}

                <div className="pt-4 px-2 pb-2">
                  <form className="form-horizontal">
                    <div className="mb-4">
                      <label htmlFor="email">Username</label>
                      <input
                        {...register("username", { required: true })}
                        name="username"
                        className="form-control"
                        placeholder="Username"
                        onKeyDown={handleEnter}
                      />

                      {errors && errors.username && (
                        <div className="invalid-feedback d-block">
                          {errors.username.type === "required" && (
                            <div>Username is required</div>
                          )}

                          {errors.username.type === "manual" && (
                            <div>{errors.username.message}</div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="password">Password</label>
                      <div className="input-group auth-pass-inputgroup">
                        <input
                          {...register("password", { required: true })}
                          type="password"
                          className="form-control"
                          name="password"
                          placeholder="Enter password"
                          aria-label="Password"
                          aria-describedby="password-addon"
                          onKeyDown={handleEnter}
                        />

                        {/* <button
                          className="btn btn-light ms-0"
                          type="button"
                          id="password-addon"
                        >
                          <i className="mdi mdi-eye-outline"></i>
                        </button> */}
                        {errors && errors.password && (
                          <div className="invalid-feedback d-block">
                            {errors.password.type === "required" && (
                              <div>Password is required</div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mt-4 d-grid">
                      <button
                        className="btn btn-primary"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Log In
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* <div class="mt-5 text-center">
              <div>© 2023 Copyright: VNNS JSC</div>

              <div className="pt-2">
                <div>
                  <i className="bx bx-home align-middle me-2"></i>
                  69/2/15 Nguyen Gia Tri - Binh Thanh - HCM City
                </div>
                <div>
                  <i className="bx bx-envelope align-middle me-2"></i>
                  info@vnns.io
                </div>
                <div>
                  <i className="bx bx-phone align-middle me-2"></i>
                  +84 972 35 35 35
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* <footer className="adm-footer-login">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-6 d-flex align-items-center">
              <div>© 2023 Copyright: VNNS JSC</div>
            </div>
            <div className="col-sm-12 col-md-6 pt-1 pt-md-0">
              <div className="float-md-end d-block">
                <div>
                  <i className="bx bx-home align-middle me-1"></i>
                  69/2/15 Nguyen Gia Tri - Binh Thanh - HCM City
                  &nbsp;|&nbsp;&nbsp;
                  <i className="bx bx-envelope align-middle me-1"></i>
                  info@vnns.io &nbsp;|&nbsp;&nbsp;
                  <i className="bx bx-phone align-middle me-1"></i>
                  +84 972 35 35 35
                </div>
                <div>
                  <i className="bx bx-envelope align-middle me-2"></i>
                  info@vnns.io
                </div>
                <div>
                  <i className="bx bx-phone align-middle me-2"></i>
                  +84 972 35 35 35
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer> */}
    </div>
  );
};

export default LoginPage;
