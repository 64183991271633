import React from "react";
import { useLocation, Link } from "react-router-dom";

const ActionButton = (props) => {
  const location = useLocation();

  return (
    <>
      {location.pathname === "/group" && (
        <Link to="/group/add" className="ms-4">
          <button type="button" className="btn btn-outline-primary">
            <i className="bx bx-plus font-size-16 align-middle me-2"></i> ADD
            NEW
          </button>
        </Link>
      )}

      {location.pathname === "/provider" && (
        <Link to="/provider/add" className="ms-4">
          <button type="button" className="btn btn-outline-primary">
            <i className="bx bx-plus font-size-16 align-middle me-2"></i> ADD
            NEW
          </button>
        </Link>
      )}

      {location.pathname === "/provider-rule" && (
        <Link to="/provider-rule/add" className="ms-4">
          <button type="button" className="btn btn-outline-primary">
            <i className="bx bx-plus font-size-16 align-middle me-2"></i> ADD
            NEW
          </button>
        </Link>
      )}

      {location.pathname === "/role" && (
        <Link to="/role/add" className="ms-4">
          <button type="button" className="btn btn-outline-primary">
            <i className="bx bx-plus font-size-16 align-middle me-2"></i> ADD
            NEW
          </button>
        </Link>
      )}

      {location.pathname === "/account" && (
        <Link to="/account/add" className="ms-4">
          <button type="button" className="btn btn-outline-primary">
            <i className="bx bx-plus font-size-16 align-middle me-2"></i> ADD
            NEW
          </button>
        </Link>
      )}

      {location.pathname === "/user" && (
        <Link to="/user/add" className="ms-4">
          <button type="button" className="btn btn-outline-primary">
            <i className="bx bx-plus font-size-16 align-middle me-2"></i> ADD
            NEW
          </button>
        </Link>
      )}

      {location.pathname === "/promo-code" && (
        <Link to="/promo-code/add" className="ms-4">
          <button type="button" className="btn btn-outline-primary">
            <i className="bx bx-plus font-size-16 align-middle me-2"></i> ADD
            NEW
          </button>
        </Link>
      )}

      {location.pathname === "/order" && (
        <Link to="/order/add" className="ms-4">
          <button type="button" className="btn btn-outline-primary">
            <i className="bx bx-plus font-size-16 align-middle me-2"></i> ADD
            NEW
          </button>
        </Link>
      )}
    </>
  );
};

export default ActionButton;
