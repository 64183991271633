import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getData } from "common/utils";
import TextField from "components/form/TextField";
import Checkbox from "components/form/Checkbox";
import Alert from "react-bootstrap/Alert";

const RoleFormPage = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [isShowAlert, setIsShowAlert] = useState(false);
  const [name, setName] = useState("");

  const { data: dataInfoRoleList } = useQuery(
    ["getInfoRoleList", id],
    async () => {
      if (id) {
        return await getData("GET", `admin/roleadmin`);
      } else {
        return null;
      }
    }
  );

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData("PUT", `admin/roleadmin/${id}`, dataForm);
      } else {
        return await getData("POST", "admin/roleadmin", dataForm);
      }
    }
  });

  useEffect(() => {
    if (dataInfoRoleList && Array.isArray(dataInfoRoleList)) {
      for (let i = 0; i < dataInfoRoleList.length; i++) {
        if (`${dataInfoRoleList[i].id}` === id) {
          setName(dataInfoRoleList[i].name);
          setValue(
            "name",
            dataInfoRoleList[i].name ? dataInfoRoleList[i].name : ""
          );
          setValue("view", dataInfoRoleList[i].view === 1);
          setValue("viewreport", dataInfoRoleList[i].viewreport === 1);
          setValue("modify", dataInfoRoleList[i].modify === 1);
          setValue("deleted", dataInfoRoleList[i].deleted === 1);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInfoRoleList, id]);

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    try {
      if (id) {
        if (name === data.name) {
          // Duplicate
          await mutation.mutateAsync({
            name: "@@##",
            view:
              typeof data.view === "undefined" ? 1 : data.view === true ? 1 : 0,
            viewreport:
              typeof data.viewreport === "undefined"
                ? 1
                : data.viewreport === true
                ? 1
                : 0,
            modify:
              typeof data.modify === "undefined"
                ? 1
                : data.modify === true
                ? 1
                : 0,
            deleted:
              typeof data.deleted === "undefined"
                ? 1
                : data.deleted === true
                ? 1
                : 0,
          });

          await mutation.mutateAsync({
            name: data.name,
            view:
              typeof data.view === "undefined" ? 1 : data.view === true ? 1 : 0,
            viewreport:
              typeof data.viewreport === "undefined"
                ? 1
                : data.viewreport === true
                ? 1
                : 0,
            modify:
              typeof data.modify === "undefined"
                ? 1
                : data.modify === true
                ? 1
                : 0,
            deleted:
              typeof data.deleted === "undefined"
                ? 1
                : data.deleted === true
                ? 1
                : 0,
          });
        } else {
          await mutation.mutateAsync({
            name: data.name,
            view:
              typeof data.view === "undefined" ? 1 : data.view === true ? 1 : 0,
            viewreport:
              typeof data.viewreport === "undefined"
                ? 1
                : data.viewreport === true
                ? 1
                : 0,
            modify:
              typeof data.modify === "undefined"
                ? 1
                : data.modify === true
                ? 1
                : 0,
            deleted:
              typeof data.deleted === "undefined"
                ? 1
                : data.deleted === true
                ? 1
                : 0,
          });
        }
      } else {
        await mutation.mutateAsync({
          name: data.name,
          view:
            typeof data.view === "undefined" ? 1 : data.view === true ? 1 : 0,
          viewreport:
            typeof data.viewreport === "undefined"
              ? 1
              : data.viewreport === true
              ? 1
              : 0,
          modify:
            typeof data.modify === "undefined"
              ? 1
              : data.modify === true
              ? 1
              : 0,
          deleted:
            typeof data.deleted === "undefined"
              ? 1
              : data.deleted === true
              ? 1
              : 0,
        });
      }

      setIsShowAlert(false);
      navigate(-1);
    } catch (err) {
      console.log(err);
      setIsShowAlert(true);
    }
  };

  const handleClickCancel = () => {
    navigate(-1);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            {isShowAlert && (
              <div className="row mb-2">
                <div className="col-md-7">
                  <Alert variant="danger">Something went wrong!</Alert>
                </div>
              </div>
            )}
            <div className="row mb-4">
              <TextField
                label="Name"
                labelClassName="col-md-2 col-form-label"
                fieldClassName="col-md-5"
                name="name"
                required
                control={control}
                error={errors.name}
              />
            </div>

            <div className="row mb-4">
              <div className="col-md-5 offset-md-2">
                <Checkbox
                  label="View"
                  name="view"
                  defaultChecked
                  control={control}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-5 offset-md-2">
                <Checkbox
                  label="View Report"
                  name="viewreport"
                  defaultChecked
                  control={control}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-5 offset-md-2">
                <Checkbox
                  label="Modify"
                  name="modify"
                  defaultChecked
                  control={control}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-5 offset-md-2">
                <Checkbox
                  label="Deleted"
                  name="deleted"
                  defaultChecked
                  control={control}
                />
              </div>
            </div>

            <div className="mt-5 row justify-content-end">
              <div className="col-md-10 offset-md-2">
                <button
                  type="submit"
                  className="btn btn-secondary w-md me-4"
                  onClick={handleClickCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary w-md"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleFormPage;
