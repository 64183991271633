import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getData, formatYearMonth, formatThousand } from "common/utils";
import BarChart from "components/chart/BarChart";
import Spinner from "components/ui/Spinner";
import Table from "components/ui/Table";

const HomeProviderTransferMonth = (props) => {
  const { provider, startDate } = props;

  const [dataList, setDataList] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [gbytesList, setGbytesList] = useState([]);

  const columns = [
    {
      Header: "Provider",
      accessor: "provider",
    },
    {
      Header: "GB",
      accessor: "gbytes",
    },
  ];

  const {
    data: dataMonth,
    isLoading,
    isFetching,
  } = useQuery(["getProviderDataMonth", provider, startDate], async () => {
    if (provider && startDate) {
      return await getData(
        "GET",
        `count/providerdatamonth/${provider.value}/${formatYearMonth(
          startDate
        )}`
      );
    } else {
      return null;
    }
  });

  useEffect(() => {
    if (dataMonth && Array.isArray(dataMonth)) {
      const dataTemp = dataMonth.map((item, index) => {
        return {
          key: index,
          provider: item.provider,
          gbytes: formatThousand(item.gbytes),
        };
      });
      setDataList(dataTemp);

      const providerTemp = dataMonth.map((item) => {
        return item.provider;
      });
      const gbytesTemp = dataMonth.map((item) => {
        return item.gbytes;
      });
      setProviderList(providerTemp);
      setGbytesList(gbytesTemp);
    }
  }, [dataMonth]);

  return (
    <div className="report-data-transfer">
      {isLoading || isFetching ? (
        <Spinner />
      ) : (
        <BarChart
          id="home-transfer-month-chart"
          labels={providerList}
          datasets={[
            {
              fill: true,
              lineTension: 0.5,
              backgroundColor: [
                "rgba(85, 110, 230, 0.2)",
                "rgba(255, 205, 86, 0.2)",
              ],
              borderColor: ["#556ee6", "#ffcd56"],
              borderCapStyle: "butt",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: ["#556ee6", "#ffcd56"],
              pointBackgroundColor: "#fff",
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: ["#556ee6", "#ffcd56"],
              pointHoverBorderColor: "#fff",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              label: "GB",
              barThickness: 30,
              borderWidth: 3,
              data: gbytesList,
            },
          ]}
        />
      )}

      <div className="card">
        <div className="card-body">
          <Table columns={columns} data={dataList} />
        </div>
      </div>
    </div>
  );
};

export default HomeProviderTransferMonth;
