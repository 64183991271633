import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getData } from "common/utils";
import TextField from "components/form/TextField";
import Select from "components/form/Select";
import Checkbox from "components/form/Checkbox";
import Alert from "react-bootstrap/Alert";

const ProviderRuleFormPage = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [isShowAlert, setIsShowAlert] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [provider, setProvider] = useState("");

  const { data: dataInfoProviderRuleList } = useQuery(
    ["getInfoProviderRuleList", id],
    async () => {
      if (id) {
        return await getData("GET", `admin/providerrule`);
      } else {
        return null;
      }
    }
  );

  const { data: dataProviderRuleGroupList } = useQuery(
    ["getProviderRuleGroupList"],
    async () => {
      return await getData("GET", "admin/group");
    }
  );

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData("PUT", `admin/providerrule/${id}`, dataForm);
      } else {
        return await getData("POST", "admin/providerrule", dataForm);
      }
    }
  });

  useEffect(() => {
    if (
      dataInfoProviderRuleList &&
      Array.isArray(dataInfoProviderRuleList && groupList)
    ) {
      for (let i = 0; i < dataInfoProviderRuleList.length; i++) {
        if (`${dataInfoProviderRuleList[i].id}` === id) {
          setProvider(dataInfoProviderRuleList[i].provider);
          setValue(
            "provider",
            dataInfoProviderRuleList[i].provider
              ? dataInfoProviderRuleList[i].provider
              : ""
          );
          setValue("permit", dataInfoProviderRuleList[i].permit === 1);

          // Group
          const groupFind = groupList.find(
            (item) => item.value === dataInfoProviderRuleList[i].group_id
          );
          setValue("group", groupFind);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInfoProviderRuleList, groupList, id]);

  useEffect(() => {
    if (dataProviderRuleGroupList && Array.isArray(dataProviderRuleGroupList)) {
      const selectData = dataProviderRuleGroupList.map((item) => {
        return {
          value: item.id,
          label: item.group_name,
        };
      });
      setGroupList(selectData);
    }
  }, [dataProviderRuleGroupList]);

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    try {
      if (id) {
        if (provider === data.provider) {
          // Duplicate
          await mutation.mutateAsync({
            provider: "@@##",
            group_id: data.group && data.group.value,
            permit:
              typeof data.permit === "undefined"
                ? 1
                : data.permit === true
                ? 1
                : 0,
          });

          await mutation.mutateAsync({
            provider: data.provider,
            group_id: data.group && data.group.value,
            permit:
              typeof data.permit === "undefined"
                ? 1
                : data.permit === true
                ? 1
                : 0,
          });
        } else {
          await mutation.mutateAsync({
            provider: data.provider,
            group_id: data.group && data.group.value,
            permit:
              typeof data.permit === "undefined"
                ? 1
                : data.permit === true
                ? 1
                : 0,
          });
        }
      } else {
        await mutation.mutateAsync({
          provider: data.provider,
          group_id: data.group && data.group.value,
          permit:
            typeof data.permit === "undefined"
              ? 1
              : data.permit === true
              ? 1
              : 0,
        });
      }

      setIsShowAlert(false);
      navigate(-1);
    } catch (err) {
      console.log(err);
      setIsShowAlert(true);
    }
  };

  const handleClickCancel = () => {
    navigate(-1);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            {isShowAlert && (
              <div className="row mb-2">
                <div className="col-md-7">
                  <Alert variant="danger">Something went wrong!</Alert>
                </div>
              </div>
            )}
            <div className="row mb-4">
              <TextField
                label="Provider"
                labelClassName="col-md-2 col-form-label"
                fieldClassName="col-md-5"
                name="provider"
                required
                control={control}
                error={errors.provider}
              />
            </div>

            <div className="row mb-4">
              <Select
                label="Group"
                labelClassName="col-md-2 col-form-label"
                fieldClassName="col-md-5"
                name="group"
                options={groupList}
                required
                control={control}
                error={errors.group}
              />
            </div>

            <div className="row mb-4">
              <div className="col-md-5 offset-md-2">
                <Checkbox
                  label="Permit"
                  name="permit"
                  defaultChecked
                  control={control}
                  error={errors.permit}
                />
              </div>
            </div>

            <div className="mt-5 row justify-content-end">
              <div className="col-md-10 offset-md-2">
                <button
                  type="submit"
                  className="btn btn-secondary w-md me-4"
                  onClick={handleClickCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary w-md"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderRuleFormPage;
