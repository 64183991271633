import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  getData,
  formatYearMonth,
  formatThousand,
  getChartBackgroundColor,
  getChartBorderColor,
} from "common/utils";
import BarChart from "components/chart/BarChart";
import Spinner from "components/ui/Spinner";
import Table from "components/ui/Table";

const HomeDataCenterTransferMonth = (props) => {
  const { provider, dataCenter, startDate } = props;

  const [dataList, setDataList] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [gbytesList, setGbytesList] = useState([]);

  const columns = [
    {
      Header: "Provider",
      accessor: "provider",
    },
    {
      Header: "Data Center",
      accessor: "dc",
    },
    {
      Header: "GB",
      accessor: "gbytes",
    },
  ];

  const {
    data: dataMonth,
    isLoading,
    isFetching,
  } = useQuery(
    ["getHomeDataCenterMonth", provider, dataCenter, startDate],
    async () => {
      if (provider && dataCenter && startDate) {
        return await getData(
          "GET",
          `count/dcdatamonth/${provider.value}/${
            dataCenter.value
          }/${formatYearMonth(startDate)}`
        );
      } else {
        return null;
      }
    }
  );

  useEffect(() => {
    if (dataMonth && Array.isArray(dataMonth)) {
      const dataTemp = dataMonth.map((item, index) => {
        return {
          key: index,
          provider: item.provider,
          dc: item.dc,
          gbytes: formatThousand(item.gbytes),
        };
      });
      setDataList(dataTemp);

      const providerTemp = dataMonth.map((item) => {
        return item.provider;
      });
      const gbytesTemp = dataMonth.map((item) => {
        return item.gbytes;
      });
      setProviderList(providerTemp);
      setGbytesList(gbytesTemp);
    }
  }, [dataMonth]);

  return (
    <div className="home-dc-transfer-month">
      {isLoading || isFetching ? (
        <Spinner />
      ) : (
        <BarChart
          id="home-data-center-month-chart"
          labels={providerList}
          datasets={[
            {
              fill: true,
              lineTension: 0.5,
              backgroundColor: [
                getChartBackgroundColor(1),
                getChartBackgroundColor(2),
                getChartBackgroundColor(3),
                getChartBackgroundColor(4),
                getChartBackgroundColor(5),
              ],
              borderColor: [
                getChartBorderColor(1),
                getChartBorderColor(2),
                getChartBorderColor(3),
                getChartBorderColor(4),
                getChartBorderColor(5),
              ],
              borderCapStyle: "butt",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: [
                getChartBorderColor(1),
                getChartBorderColor(2),
                getChartBorderColor(3),
                getChartBorderColor(4),
                getChartBorderColor(5),
              ],
              pointBackgroundColor: "#fff",
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: [
                getChartBorderColor(1),
                getChartBorderColor(2),
                getChartBorderColor(3),
                getChartBorderColor(4),
                getChartBorderColor(5),
              ],
              pointHoverBorderColor: "#fff",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              label: "GB",
              barThickness: 30,
              borderWidth: 3,
              data: gbytesList,
            },
          ]}
        />
      )}

      <div className="card">
        <div className="card-body">
          <Table columns={columns} data={dataList} />
        </div>
      </div>
    </div>
  );
};

export default HomeDataCenterTransferMonth;
