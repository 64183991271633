import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getData } from "common/utils";
import Table from "components/ui/Table";
import Tooltip from "components/ui/Tooltip";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";

const GroupPage = (props) => {
  const queryClient = useQueryClient();

  const [dataList, setDataList] = useState([]);
  const [show, setShow] = useState(false);
  const [idDelete, setIdDelete] = useState(null);

  const { data: dataGroup } = useQuery(["getGroup"], async () => {
    return await getData("GET", "admin/group");
  });

  const mutationDelete = useMutation(
    async (id) => {
      if (id) {
        return await getData("DELETE", `admin/group/${id}`);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getGroup");
      },
    }
  );

  const columns = [
    {
      Header: () => <div className="text-center">Action</div>,
      accessor: "action",
      width: 120,
      minWidth: 120,
      maxWidth: 120,
      Cell: (props) => {
        const idValue =
          props.row && props.row.original && props.row.original.id;

        return (
          <div className="text-center">
            <Tooltip message="Delete">
              <i
                className="bx bx-trash font-size-20 text-danger me-2"
                role="button"
                onClick={(e) => handleShow(e, idValue)}
              ></i>
            </Tooltip>
            <Tooltip message="Edit">
              <Link to={`/group/edit/${idValue}`}>
                <i
                  className="bx bx-edit-alt font-size-20 text-success ms-2 me-2"
                  role="button"
                ></i>
              </Link>
            </Tooltip>
            <Tooltip message="Group Detail">
              <Link to={`/group/group-detail/${idValue}`}>
                <i
                  className="bx bx-user-plus font-size-20 text-primary ms-2"
                  role="button"
                ></i>
              </Link>
            </Tooltip>
          </div>
        );
      },
    },
    {
      Header: "Name",
      accessor: "group_name",
    },
    {
      Header: "Updated at",
      accessor: "last_update",
    },
  ];

  useEffect(() => {
    if (dataGroup && Array.isArray(dataGroup)) {
      const dataTemp = dataGroup.map((item) => {
        return {
          key: item.id,
          ...item,
        };
      });
      setDataList(dataTemp);
    }
  }, [dataGroup]);

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleClose = () => setShow(false);
  const handleShow = (e, idValue) => {
    setIdDelete(idValue);
    setShow(true);
  };

  const handleClickDelete = async () => {
    try {
      await mutationDelete.mutateAsync(idDelete);
      setShow(false);

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Deleted successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (err) {
      setShow(false);
      console.log(err);
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <Table columns={columns} data={dataList} />
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action cannot be undone. Are you sure you want to delete this
          item?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleClickDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GroupPage;
