import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getData } from "common/utils";
import Select from "react-select";
import Table from "components/ui/Table";
import Swal from "sweetalert2";

const GroupDetailPage = (props) => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [groupDetailList, setGroupDetailList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [account, setAccount] = useState(null);

  const columns = [
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Fullname",
      accessor: "fullname",
    },
  ];

  const { data: dataGroupDetail } = useQuery(
    ["getGroupDetail", id],
    async () => {
      if (id) {
        return await getData("GET", `admin/groupdetail/${id}`);
      }
    }
  );

  const { data: dataAllUser } = useQuery(
    ["getGroupDetailAllUser"],
    async () => {
      return await getData("GET", "admin/getAllUser");
    }
  );

  const mutation = useMutation(
    async (dataForm) => {
      if (dataForm) {
        return await getData("POST", "admin/groupdetail", dataForm);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getGroupDetail");
        queryClient.invalidateQueries("getGroupDetailAllUser");
      },
    }
  );

  useEffect(() => {
    if (
      dataGroupDetail &&
      Array.isArray(dataGroupDetail) &&
      dataAllUser &&
      Array.isArray(dataAllUser)
    ) {
      const dataTemp = dataGroupDetail.map((item) => {
        return {
          key: item.detail_id,
          ...item,
        };
      });
      setGroupDetailList(dataTemp);

      // Account
      // Remove duplicate
      const arrTemp = [];
      for (let i = 0; i < dataAllUser.length; i++) {
        let isDuplicate = false;

        for (let j = 0; j < dataGroupDetail.length; j++) {
          if (dataAllUser[i].id === dataGroupDetail[j].userid) {
            isDuplicate = true;
          }
        }

        if (!isDuplicate) {
          arrTemp.push(dataAllUser[i]);
        }
      }

      const selectData = arrTemp.map((item) => {
        return {
          value: item.id,
          label: item.email,
        };
      });
      setAccountList(selectData);
    }
  }, [dataGroupDetail, dataAllUser]);

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleChangeAccount = (value) => {
    setAccount(value);
  };

  const handleAddAccount = async () => {
    try {
      if (account) {
        await mutation.mutateAsync({
          user_id: account && account.value,
          group_id: id,
        });
        setAccount(null);
      } else {
        Swal.fire({
          position: "top-end",
          icon: "warning",
          title: "Please select account",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-md-6">
                <label className="form-label">Account</label>
                <Select
                  options={accountList}
                  value={account}
                  onChange={handleChangeAccount}
                />
              </div>
              <div className="col-md-6 pt-4 ps-4">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAddAccount}
                >
                  <i className="bx bx-plus font-size-16 align-middle me-2"></i>{" "}
                  ADD
                </button>
              </div>
            </div>

            <div className="table-responsive mt-4">
              <Table columns={columns} data={groupDetailList} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupDetailPage;
