import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getData, formatYearMonth } from "common/utils";
import Select from "react-select";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MonthPicker from "components/ui/MonthPicker";
import HomeProviderTransferMonth from "../components/HomeProviderTransferMonth";
import HomeProviderTransferDate from "../components/HomeProviderTransferDate";
import HomeDataCenterTransferMonth from "../components/HomeDataCenterTransferMonth";
import HomeDataCenterTransferDate from "../components/HomeDataCenterTransferDate";
import HomeCdnTransferMonth from "../components/HomeCdnTransferMonth";
import HomeCdnTransferDate from "../components/HomeCdnTransferDate";
import "../styles/home-page.scss";

const HomePage = (props) => {
  const [providerList, setProviderList] = useState([]);
  const [provider, setProvider] = useState(null);
  const [dataCenterList, setDataCenterList] = useState([]);
  const [dataCenter, setDataCenter] = useState(null);
  const [cdnList, setCdnList] = useState([]);
  const [cdn, setCdn] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [tabKey, setTabKey] = useState("1");

  const { data: dataProviderList } = useQuery(
    ["getHomeProviderList"],
    async () => {
      return await getData("GET", "count/providerlists");
    }
  );

  const { data: dataDataCenterList } = useQuery(
    ["getHomeDataCenterList", startDate],
    async () => {
      if (startDate) {
        const startDateFormat = formatYearMonth(startDate);
        return await getData("GET", `count/dclists/${startDateFormat}`);
      } else {
        return null;
      }
    }
  );

  const { data: dataCdnList } = useQuery(["getHomeCdnList"], async () => {
    return await getData("GET", "count/cdnlist");
  });

  useEffect(() => {
    if (dataProviderList) {
      if (Array.isArray(dataProviderList)) {
        const selectData = dataProviderList.map((item) => {
          return {
            value: item,
            label: item,
          };
        });
        selectData.unshift({ value: "all", label: "All Provider" });
        setProviderList(selectData);
      }
    }
  }, [dataProviderList]);

  useEffect(() => {
    if (dataDataCenterList && Array.isArray(dataDataCenterList)) {
      const selectData = dataDataCenterList.map((item) => {
        return {
          value: item,
          label: item,
        };
      });
      selectData.unshift({ value: "all", label: "All Data Center" });
      setDataCenterList(selectData);
    }
  }, [dataDataCenterList]);

  useEffect(() => {
    if (dataCdnList && Array.isArray(dataCdnList)) {
      const selectData = dataCdnList.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setCdnList(selectData);
    }
  }, [dataCdnList]);

  useEffect(() => {
    if (providerList && providerList.length > 0) {
      setProvider(providerList[0]);
    }
  }, [providerList]);

  useEffect(() => {
    if (dataCenterList && dataCenterList.length > 0) {
      setDataCenter(dataCenterList[0]);
    }
  }, [dataCenterList]);

  useEffect(() => {
    if (cdnList && cdnList.length > 0) {
      setCdn(cdnList[0]);
    }
  }, [cdnList]);

  useEffect(() => {
    if (tabKey === "1" || tabKey === "2") {
      setDataCenter({ value: "all", label: "All Data Center" });
    } else if (tabKey === "3" || tabKey === "4") {
      setCdn(cdnList[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabKey]);

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleChangeProvider = (value) => {
    setProvider(value);
  };

  const handleChangeDataCenter = (value) => {
    setDataCenter(value);
  };

  const handleChangeCdn = (value) => {
    setCdn(value);
  };

  const handleChangeStartDate = (date) => {
    setStartDate(date);
  };

  const handleTabSelect = (key) => {
    setTabKey(key);
  };

  // const handleRefresh = () => {
  //   const cdnTemp = cdn;
  //   setCdn(null);
  //   setTimeout(() => {
  //     setCdn(cdnTemp);
  //   }, 500);
  // };

  return (
    <div className="row home-page">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-md-3">
                <label className="form-label">Provider</label>
                <Select
                  options={providerList}
                  value={provider}
                  onChange={handleChangeProvider}
                />
              </div>

              {tabKey !== "1" && tabKey !== "2" && (
                <div className="col-md-3">
                  <label className="form-label">Data Center</label>
                  <Select
                    options={dataCenterList}
                    value={dataCenter}
                    onChange={handleChangeDataCenter}
                  />
                </div>
              )}

              {tabKey !== "1" &&
                tabKey !== "2" &&
                tabKey !== "3" &&
                tabKey !== "4" && (
                  <div className="col-md-3">
                    <label className="form-label">CDN</label>
                    <Select
                      options={cdnList}
                      value={cdn}
                      onChange={handleChangeCdn}
                    />
                  </div>
                )}

              <div className="col-md-3">
                <label className="form-label">Month</label>
                <MonthPicker
                  startDate={startDate}
                  onChange={handleChangeStartDate}
                />
              </div>

              {/* <div className="col-md-4 pt-4 ps-4">
                <div className="refresh-data" onClick={handleRefresh}>
                  <i className="mdi mdi-cached me-1"></i>
                  <span>Refresh</span>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <Tabs
              defaultActiveKey="1"
              className="mb-3 nav-tabs nav-tabs-custom nav-justified"
              onSelect={handleTabSelect}
            >
              <Tab eventKey="1" title="Provider Transfer Month">
                <HomeProviderTransferMonth
                  provider={provider}
                  startDate={startDate}
                />
              </Tab>
              <Tab eventKey="2" title="Provider Transfer Date">
                <HomeProviderTransferDate
                  provider={provider}
                  startDate={startDate}
                />
              </Tab>
              <Tab eventKey="3" title="DC Transfer Month">
                <HomeDataCenterTransferMonth
                  provider={provider}
                  dataCenter={dataCenter}
                  startDate={startDate}
                />
              </Tab>
              <Tab eventKey="4" title="DC Transfer Date">
                <HomeDataCenterTransferDate
                  provider={provider}
                  dataCenter={dataCenter}
                  startDate={startDate}
                />
              </Tab>
              <Tab eventKey="5" title="CDN Transfer Month">
                <HomeCdnTransferMonth
                  provider={provider}
                  dataCenter={dataCenter}
                  cdn={cdn}
                  startDate={startDate}
                />
              </Tab>
              <Tab eventKey="6" title="CDN Transfer Date">
                <HomeCdnTransferDate
                  provider={provider}
                  dataCenter={dataCenter}
                  cdn={cdn}
                  startDate={startDate}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
