import React from "react";
import { useMatches, Outlet } from "react-router-dom";
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";
// import Footer from "./Footer";
import ActionButton from "./ActionButton";

const Layout = (props) => {
  const matches = useMatches();
  const crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => match.handle.crumb(match.data));

  return (
    <div id="layout-wrapper">
      <Topbar />

      <Sidebar />

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <h4 className="mb-0 font-size-18">
                      {crumbs && crumbs[crumbs.length - 1]}
                    </h4>

                    <ActionButton />
                  </div>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      {crumbs &&
                        crumbs.map((item, index) => {
                          return (
                            <li key={index} className="breadcrumb-item active">
                              {item}
                            </li>
                          );
                        })}
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <Outlet />
          </div>
        </div>

        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Layout;
