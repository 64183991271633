import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  getData,
  formatYearMonth,
  groupByArray,
  getChartBackgroundColor,
  getChartBorderColor,
} from "common/utils";
import LineChart from "components/chart/LineChart";
import Spinner from "components/ui/Spinner";
import Table from "components/ui/Table";

const HomeDataCenterTransferDate = (props) => {
  const { provider, dataCenter, startDate } = props;

  const [columns, setColumns] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [chartLabelList, setChartLabelList] = useState([]);
  const [chartDataList, setChartDataList] = useState([]);

  const {
    data: dataInDate,
    isLoading,
    isFetching,
  } = useQuery(
    ["getHomeDataCenterTransferDate", provider, dataCenter, startDate],
    async () => {
      if (provider && dataCenter && startDate) {
        return await getData(
          "GET",
          `count/dcdataindate/${provider.value}/${
            dataCenter.value
          }/${formatYearMonth(startDate)}`
        );
      } else {
        return null;
      }
    }
  );

  useEffect(() => {
    if (dataInDate && Array.isArray(dataInDate)) {
      const groupByArr = groupByArray(dataInDate, (item) => {
        return [item.provider, item.dc];
      });
      const dataListArr = [];
      const chartLabelArr = [];
      const chartDataArr = [];

      for (let i = 0; i < groupByArr.length; i++) {
        const chartDataTemp = [];
        const arrItem = groupByArr[i];

        if (Array.isArray(arrItem)) {
          for (let j = 0; j < arrItem.length; j++) {
            if (dataListArr[j]) {
              dataListArr[j][arrItem[j].provider + "_" + arrItem[j].dc] =
                arrItem[j].gbytes;
            } else {
              dataListArr.push({
                key: j,
                date: arrItem[j].date,
                [arrItem[j].provider + "_" + arrItem[j].dc]: arrItem[j].gbytes,
              });

              const dateTemp = arrItem[j].date;
              if (typeof dateTemp === "string") {
                chartLabelArr.push(dateTemp.slice(-2));
              }
            }

            // Data Chart
            chartDataTemp.push(arrItem[j].gbytes);
          }
        }

        chartDataArr.push({
          fill: true,
          lineTension: 0.5,
          backgroundColor: getChartBackgroundColor(i),
          borderColor: getChartBorderColor(i),
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: getChartBorderColor(i),
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: getChartBorderColor(i),
          pointHoverBorderColor: "#fff",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          label: arrItem[0] && arrItem[0].provider + "_" + arrItem[0].dc,
          data: chartDataTemp,
          barThickness: 30,
          borderWidth: 3,
          // stack: "Stack 4x",
        });
      }

      // Columns
      const columnObj = dataListArr[0];
      const columnArr = [];

      if (columnObj && typeof columnObj === "object") {
        for (const key in columnObj) {
          if (key === "key" || key === "date") {
            //
          } else {
            columnArr.push({
              Header: key + " (GB)",
              accessor: key,
            });
          }
        }
      }

      const columnTemp = [
        {
          Header: "Date",
          accessor: "date",
        },
      ];
      const columnConcat = columnTemp.concat(columnArr);

      setChartLabelList(chartLabelArr);
      setChartDataList(chartDataArr);
      setColumns(columnConcat);
      setDataList(dataListArr);
    }
  }, [dataInDate]);

  return (
    <div>
      {isLoading || isFetching ? (
        <Spinner />
      ) : (
        <LineChart
          id="home-data-center-transfer-date-chart"
          xLabel="Date"
          yLabel="GB"
          labels={chartLabelList}
          datasets={chartDataList}
        />
      )}

      <div className="card">
        <div className="card-body">
          <Table columns={columns} data={dataList} />
        </div>
      </div>
    </div>
  );
};

export default HomeDataCenterTransferDate;
