import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  getData,
  formatYearMonth,
  formatThousand,
  groupBy,
  getChartBackgroundColor,
  getChartBorderColor,
} from "common/utils";
import LineChart from "components/chart/LineChart";
import Spinner from "components/ui/Spinner";
import Table from "components/ui/Table";

const HomeProviderTransferDate = (props) => {
  const { provider, startDate } = props;

  const [columns, setColumns] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [chartLabelList, setChartLabelList] = useState([]);
  const [chartDataList, setChartDataList] = useState([]);

  const {
    data: dataInDate,
    isLoading,
    isFetching,
  } = useQuery(["getProviderDataInDate", provider, startDate], async () => {
    if (provider && startDate) {
      return await getData(
        "GET",
        `count/providerdataindate/${provider.value}/${formatYearMonth(
          startDate
        )}`
      );
    } else {
      return null;
    }
  });

  useEffect(() => {
    if (dataInDate && Array.isArray(dataInDate)) {
      const groupByObj = groupBy(dataInDate, "provider");
      const chartLabelArr = [];
      const chartDataArr = [];
      const tempArr = [];
      let indexTemp = 0;

      for (const key in groupByObj) {
        const providerArr = groupByObj[key];
        const chartDataTemp = [];

        if (providerArr && Array.isArray(providerArr)) {
          for (let i = 0; i < providerArr.length; i++) {
            if (tempArr[i]) {
              tempArr[i][providerArr[i].provider] = formatThousand(
                providerArr[i].gbytes
              );
            } else {
              tempArr.push({
                key: i,
                date: providerArr[i].date,
                [providerArr[i].provider]: formatThousand(
                  providerArr[i].gbytes
                ),
              });

              const dateTemp = providerArr[i].date;
              if (typeof dateTemp === "string") {
                chartLabelArr.push(dateTemp.slice(-2));
              }
            }

            // Data Chart
            chartDataTemp.push(providerArr[i].gbytes);
          }
        }

        chartDataArr.push({
          fill: true,
          lineTension: 0.5,
          backgroundColor: getChartBackgroundColor(indexTemp),
          borderColor: getChartBorderColor(indexTemp),
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: getChartBorderColor(indexTemp),
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: getChartBorderColor(indexTemp),
          pointHoverBorderColor: "#fff",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          label: providerArr[0] && providerArr[0].provider,
          data: chartDataTemp,
          barThickness: 30,
          borderWidth: 3,
          stack: "Stack 4x",
        });

        indexTemp++;
      }

      // Columns
      const columnObj = tempArr[0];
      const columnArr = [];

      if (columnObj && typeof columnObj === "object") {
        for (const key in columnObj) {
          if (key === "key" || key === "date") {
            //
          } else {
            columnArr.push({
              Header: key + " (GB)",
              accessor: key,
            });
          }
        }
      }

      const columnTemp = [
        {
          Header: "Date",
          accessor: "date",
        },
      ];
      const columnConcat = columnTemp.concat(columnArr);

      setChartLabelList(chartLabelArr);
      setChartDataList(chartDataArr);
      setColumns(columnConcat);
      setDataList(tempArr);
    }
  }, [dataInDate]);

  return (
    <div>
      {isLoading || isFetching ? (
        <Spinner />
      ) : (
        <LineChart
          id="home-transfer-date-chart"
          xLabel="Date"
          yLabel="GB"
          labels={chartLabelList}
          datasets={chartDataList}
        />
      )}

      <div className="card">
        <div className="card-body">
          <Table columns={columns} data={dataList} />
        </div>
      </div>
    </div>
  );
};

export default HomeProviderTransferDate;
