import React from "react";
import "./styles/card-stat.scss";

const CardStat = (props) => {
  const { title, value, icon } = props;

  return (
    <div className="col-md-3 card-stat">
      <div className="card mini-stats-wid">
        <div className="card-body">
          <div className="media">
            <div className="media-body">
              <p className="text-muted fw-medium">{title}</p>
              <h4 className="mb-0">{value}</h4>
            </div>

            <div
              className={`mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center ${
                title === "Total"
                  ? "avatar-total"
                  : title === "Active"
                  ? "avatar-active"
                  : title === "Inactive"
                  ? "avatar-inactive"
                  : title === "Pending"
                  ? "avatar-pending"
                  : ""
              }`}
            >
              <span className="avatar-title">
                <i className={`bx ${icon} font-size-24`}></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardStat;
