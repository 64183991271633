import axios from "axios";

const callApi = (method, endpoint, data) => {
  const sessionId = localStorage.getItem("token");

  return axios({
    headers: {
      Authorization: `Bearer ${sessionId}`,
    },
    method: method,
    url: `${process.env.REACT_APP_API_URL}${endpoint}`,
    // url: `${process.env.REACT_APP_API_URL}?${endpoint}`,
    data: data,
  });
};

const callLoginApi = (method, endpoint, data) => {
  return axios({
    method: method,
    url: `${process.env.REACT_APP_API_URL}${endpoint}`,
    data: data,
  });
};

export const getData = (method, endpoint, dataSend) => {
  return new Promise(async (resolve, reject) => {
    /* eslint-disable-line */
    try {
      const result = await callApi(method, endpoint, dataSend);
      const { data } = result;
      return data ? resolve(data) : resolve(null);
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          // alert('Session expired, please login again.')
          localStorage.removeItem("token");
          window.location.reload();
        }
      }
      console.log(err);
      return reject(err);
    }
  });
};

export const getLoginData = (method, endpoint, dataSend) => {
  return new Promise(async (resolve, reject) => {
    /* eslint-disable-line */
    try {
      const result = await callLoginApi(method, endpoint, dataSend);
      const { data } = result;

      return data ? resolve(data) : resolve(null);
    } catch (e) {
      console.log(e);
      return reject(e);
    }
  });
};

export const formatDate = (date) => {
  if (date instanceof Date) {
    const year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    return year + month + day;
  } else {
    return "";
  }
};

export const formatYearMonth = (date) => {
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  return year + month;
};

export const formatVietNamDate = (date) => {
  if (date instanceof Date) {
    const year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    return day + "/" + month + "/" + year;
  } else {
    return "";
  }
};

export const formatYearMonthCustom = (date, format) => {
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  if (format === "mm/yyyy") {
    return month + "/" + year;
  } else {
    return year + month;
  }
};

export const formatThousand = (number) => {
  if (number) {
    if (typeof number === "string") {
      return number.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } else {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  }
  return "0";
};

export const formatVietNamDateTime = (date) => {
  if (date instanceof Date) {
    const year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    let hour = date.getHours().toString();
    hour = hour.length > 1 ? hour : "0" + hour;

    let minute = date.getMinutes().toString();
    minute = minute.length > 1 ? minute : "0" + minute;

    let second = date.getSeconds().toString();
    second = second.length > 1 ? second : "0" + second;

    return (
      day + "/" + month + "/" + year + " " + hour + ":" + minute + ":" + second
    );
  } else {
    return "";
  }
};

export const formatUnixTimestamp = (timestamp) => {
  const date = new Date(timestamp * 1000);

  if (date instanceof Date) {
    const year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    let hour = date.getHours().toString();
    hour = hour.length > 1 ? hour : "0" + hour;

    let minute = date.getMinutes().toString();
    minute = minute.length > 1 ? minute : "0" + minute;

    let second = date.getSeconds().toString();
    second = second.length > 1 ? second : "0" + second;

    return (
      year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second
    );
  } else {
    return "";
  }
};

export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const groupByArray = (xs, key) => {
  if (typeof key === "function") {
    const groups = {};
    xs.forEach(function (o) {
      const group = JSON.stringify(key(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    });
  } else {
    return [];
  }
};

export const getChartBackgroundColor = (index) => {
  if (index === 0) {
    return "rgba(85, 110, 230, 0.2)";
  } else if (index === 1) {
    return "rgba(255, 205, 86, 0.2)";
  } else if (index === 2) {
    return "rgba(232, 62, 140, 0.2)";
  } else if (index === 3) {
    return "	rgba(52, 195, 143, 0.2)";
  } else if (index === 4) {
    return "	rgba(244, 106, 106, 0.2)";
  } else {
    return "rgba(85, 110, 230, 0.2)";
  }
};

export const getChartBorderColor = (index) => {
  if (index === 0) {
    return "#556ee6";
  } else if (index === 1) {
    return "#ffcd56";
  } else if (index === 2) {
    return "#e83e8c";
  } else if (index === 3) {
    return "#34c38f";
  } else if (index === 4) {
    return "#f46a6a";
  } else {
    return "#556ee6";
  }
};
