import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getData } from "common/utils";
import TextField from "components/form/TextField";
import Alert from "react-bootstrap/Alert";

const ProviderFormPage = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [isShowAlert, setIsShowAlert] = useState(false);
  const [name, setName] = useState("");

  const { data: dataInfoProviderList } = useQuery(
    ["getInfoProviderList", id],
    async () => {
      if (id) {
        return await getData("GET", `admin/provider`);
      } else {
        return null;
      }
    }
  );

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData("PUT", `admin/provider/${id}`, dataForm);
      } else {
        return await getData("POST", "admin/provider", dataForm);
      }
    }
  });

  useEffect(() => {
    if (dataInfoProviderList && Array.isArray(dataInfoProviderList)) {
      for (let i = 0; i < dataInfoProviderList.length; i++) {
        if (`${dataInfoProviderList[i].id}` === id) {
          setName(dataInfoProviderList[i].name);
          setValue(
            "name",
            dataInfoProviderList[i].name ? dataInfoProviderList[i].name : ""
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInfoProviderList]);

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    try {
      if (id) {
        if (name === data.name) {
          // Duplicate
          //
        } else {
          await mutation.mutateAsync({
            name: data.name,
          });
        }
      } else {
        await mutation.mutateAsync({
          name: data.name,
        });
      }

      setIsShowAlert(false);
      navigate(-1);
    } catch (err) {
      console.log(err);
      setIsShowAlert(true);
    }
  };

  const handleClickCancel = () => {
    navigate(-1);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            {isShowAlert && (
              <div className="row mb-2">
                <div className="col-md-7">
                  <Alert variant="danger">Something went wrong!</Alert>
                </div>
              </div>
            )}
            <div className="row mb-4">
              <TextField
                label="Name"
                labelClassName="col-md-2 col-form-label"
                fieldClassName="col-md-5"
                name="name"
                required
                control={control}
                error={errors.name}
              />
            </div>

            <div className="mt-5 row justify-content-end">
              <div className="col-md-10 offset-md-2">
                <button
                  type="submit"
                  className="btn btn-secondary w-md me-4"
                  onClick={handleClickCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary w-md"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderFormPage;
