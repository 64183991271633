import React from "react";
import SpinnerBootstrap from "react-bootstrap/Spinner";
import "./styles/spinner.scss";

const Spinner = (props) => {
  return (
    <div className="spinner-ui">
      <SpinnerBootstrap animation="border" variant="primary" />
    </div>
  );
};

export default Spinner;
