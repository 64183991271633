import React from "react";
import {
  createBrowserRouter,
  Navigate,
  Link,
  Outlet,
  useLocation,
} from "react-router-dom";
import { useAuth } from "context/AuthContext";
import Layout from "modules/layout/components/Layout";
import LoginPage from "modules/auth/pages/LoginPage";
import HomePage from "modules/home/pages/HomePage";
import GroupPage from "modules/group/pages/GroupPage";
import GroupFormPage from "modules/group/pages/GroupFormPage";
import GroupDetailPage from "modules/group/pages/GroupDetailPage";
import ProviderPage from "modules/provider/pages/ProviderPage";
import ProviderFormPage from "modules/provider/pages/ProviderFormPage";
import ProviderRulePage from "modules/provider/pages/ProviderRulePage";
import ProviderRuleFormPage from "modules/provider/pages/ProviderRuleFormPage";
import RolePage from "modules/role/pages/RolePage";
import RoleFormPage from "modules/role/pages/RoleFormPage";
import AccountPage from "modules/account/pages/AccountPage";
import AccountFormPage from "modules/account/pages/AccountFormPage";
import UserPage from "modules/user/pages/UserPage";
import UserFormPage from "modules/user/pages/UserFormPage";
import PromoCodePage from "modules/promo-code/pages/PromoCodePage";
import PromoCodeFormPage from "modules/promo-code/pages/PromoCodeFormPage";
import OrderPage from "modules/order/pages/OrderPage";
import OrderFormPage from "modules/order/pages/OrderFormPage";
import InvoicePage from "modules/invoice/pages/InvoicePage";

const PublicRoute = ({ children }) => {
  const urlStr = window.location.href;
  const url = new URL(urlStr);
  const tokenParamUrl = url.searchParams.get("token");
  const usernameParamUrl = url.searchParams.get("username");
  if (tokenParamUrl && usernameParamUrl) {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.setItem("token", tokenParamUrl);
    localStorage.setItem("username", usernameParamUrl);
    window.location.href = "/";
  }

  const auth = useAuth();

  if (auth) {
    if (auth.token) {
      return <Navigate to="/" replace />;
    }
  }

  return children;
};

const RequireAuth = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();

  if (auth) {
    if (!auth.token) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  }

  return children;
};

const routes = createBrowserRouter([
  {
    path: "/login",
    element: (
      <PublicRoute>
        <LoginPage />
      </PublicRoute>
    ),
  },
  {
    path: "/",
    element: (
      <RequireAuth>
        <Layout />
      </RequireAuth>
    ),
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "group",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <GroupPage />,
          },
          {
            path: "add",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <GroupFormPage />,
              },
            ],
            handle: {
              crumb: () => <span>Add Group</span>,
            },
          },
          {
            path: "edit/:id",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <GroupFormPage />,
              },
            ],
            handle: {
              crumb: () => <span>Edit Group</span>,
            },
          },
          {
            path: "group-detail/:id",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <GroupDetailPage />,
              },
            ],
            handle: {
              crumb: () => <span>Group Detail</span>,
            },
          },
        ],
        handle: {
          crumb: () => <Link to="/group">Group</Link>,
        },
      },
      {
        path: "provider",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <ProviderPage />,
          },
          {
            path: "add",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <ProviderFormPage />,
              },
            ],
            handle: {
              crumb: () => <span>Add Provider</span>,
            },
          },
          {
            path: "edit/:id",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <ProviderFormPage />,
              },
            ],
            handle: {
              crumb: () => <span>Edit Provider</span>,
            },
          },
        ],
        handle: {
          crumb: () => <Link to="/provider">Provider</Link>,
        },
      },
      {
        path: "provider-rule",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <ProviderRulePage />,
          },
          {
            path: "add",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <ProviderRuleFormPage />,
              },
            ],
            handle: {
              crumb: () => <span>Add Provider Rule</span>,
            },
          },
          {
            path: "edit/:id",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <ProviderRuleFormPage />,
              },
            ],
            handle: {
              crumb: () => <span>Edit Provider Rule</span>,
            },
          },
        ],
        handle: {
          crumb: () => <Link to="/provider-rule">Provider Rule</Link>,
        },
      },
      {
        path: "role",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <RolePage />,
          },
          {
            path: "add",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <RoleFormPage />,
              },
            ],
            handle: {
              crumb: () => <span>Add Role</span>,
            },
          },
          {
            path: "edit/:id",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <RoleFormPage />,
              },
            ],
            handle: {
              crumb: () => <span>Edit Role</span>,
            },
          },
        ],
        handle: {
          crumb: () => <Link to="/role">Role</Link>,
        },
      },
      {
        path: "account",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <AccountPage />,
          },
          {
            path: "add",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <AccountFormPage />,
              },
            ],
            handle: {
              crumb: () => <span>Add Account</span>,
            },
          },
          {
            path: "edit/:id",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <AccountFormPage />,
              },
            ],
            handle: {
              crumb: () => <span>Edit Account</span>,
            },
          },
        ],
        handle: {
          crumb: () => <Link to="/account">Account</Link>,
        },
      },
      {
        path: "user",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <UserPage />,
          },
          {
            path: "add",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <UserFormPage />,
              },
            ],
            handle: {
              crumb: () => <span>Add User</span>,
            },
          },
          {
            path: "edit/:id",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <UserFormPage />,
              },
            ],
            handle: {
              crumb: () => <span>Edit User</span>,
            },
          },
        ],
        handle: {
          crumb: () => <Link to="/user">User</Link>,
        },
      },
      {
        path: "promo-code",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <PromoCodePage />,
          },
          {
            path: "add",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <PromoCodeFormPage />,
              },
            ],
            handle: {
              crumb: () => <span>Add Promo Code</span>,
            },
          },
          {
            path: "edit/:id",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <PromoCodeFormPage />,
              },
            ],
            handle: {
              crumb: () => <span>Edit Promo Code</span>,
            },
          },
        ],
        handle: {
          crumb: () => <Link to="/promo-code">Promo Code</Link>,
        },
      },

      // Order
      {
        path: "order",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <OrderPage />,
          },
          {
            path: "add",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <OrderFormPage />,
              },
            ],
            handle: {
              crumb: () => <span>Add Order</span>,
            },
          },
          {
            path: "edit/:id",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <OrderFormPage />,
              },
            ],
            handle: {
              crumb: () => <span>Edit Order</span>,
            },
          },
        ],
        handle: {
          crumb: () => <Link to="/order">Order</Link>,
        },
      },

      // Invoice
      {
        path: "invoice",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <InvoicePage />,
          },
        ],
        handle: {
          crumb: () => <Link to="/invoice">Invoice</Link>,
        },
      },
    ],
    handle: {
      crumb: () => <Link to="/">Home</Link>,
    },
  },
]);

export default routes;
