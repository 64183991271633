import React from "react";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import TooltipBootstrap from "react-bootstrap/Tooltip";

const Tooltip = (props) => {
  const { children, message } = props;

  const renderTooltip = (props) => (
    <TooltipBootstrap {...props}>{message}</TooltipBootstrap>
  );

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 100, hide: 200 }}
      overlay={renderTooltip}
    >
      {children}
    </OverlayTrigger>
  );
};

Tooltip.propTypes = {
  children: PropTypes.any,
  message: PropTypes.string,
};

Tooltip.defaultProps = {
  message: "",
};

export default Tooltip;
